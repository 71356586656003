import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import Cookies from 'js-cookie';
import inchatArrow from '../res/inchat_arrow.webp';
import inchatTranslate from '../res/inchat_translate.webp';
import inchatVideo from '../res/inchat_video.webp';
import inchatMenu from '../res/inchat_menu.webp';
import sendbutton from '../res/sendbutton.webp';
import topup from '../res/topup.webp'
import payingcard1 from '../res/payingcard1.webp'
import payingcard2 from '../res/payingcard2.webp'
import { Elements } from '@stripe/react-stripe-js'; // Импортируем Elements из Stripe
import { loadStripe } from '@stripe/stripe-js'; // Импортируем loadStripe
import Stripe from '../components/Stripe'; // Импортируем твой компонент PaymentModal
import { logAmplitudeEvent } from '../amplitude';

interface InChatProps {
  chat: any;
  onBack: () => void;
}

const ContinueVipButton = styled.button`
  margin-top: 20px;
  width: 300px; // Ширина кнопки
  background-color: #FCD900; 
  color: black;
  font-size: 20px; 
  font-weight: bold;
  padding: 15px 0; 
  border: none;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: background-color 0.3s ease;
  margin-left: auto;
  margin-right: auto;
  display: block; // Центрирование кнопки
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  height: 100vh;
  justify-content: space-between;
`;

const Header = styled.div`
width: 90vw;
height: 60px;
padding: 10px 10px;
box-sizing: border-box;
display: flex;
align-items: center;
position: fixed;
/* bottom: 0; */
background-color: #fff;
/* border-top: 1px solid #e0e0e0; */
z-index: 1000;
top: 0;
`;

const BackArrow = styled.img`
  width: 28px;
  height: 28px;
  cursor: pointer;
`;

const ProfileSection = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin-left: 10px;
`;

const ProfileImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  margin-right: 10px;
`;

const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProfileName = styled.div`
  font-size: 18px;
  font-weight: 500;
`;

const ProfileStatus = styled.div`
  font-size: 14px;
  color: #888;
  display: flex;
  align-items: center;
`;

const StatusBadge = styled.div`
  background-color: green;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-left: 5px;
`;

const IconGroup = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled.img`
  width: 28px;
  height: 28px;
  margin-left: 10px;
  cursor: pointer;
`;

const ChatContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  padding: 30px 20px;
  padding-bottom: 60px;
  box-sizing: border-box;
  overflow-y: auto;
`;

const DateSeparator = styled.div`
  text-align: center;
  color: #888;
  font-size: 14px;
  margin: 20px 0;
`;

const Message = styled.div<{ isOwn?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: ${props => (props.isOwn ? 'flex-end' : 'flex-start')};
  margin-bottom: 10px;
`;

const MessageBubble = styled.div<{ isOwn?: boolean }>`
  background-color: ${props => (props.isOwn ? '#fdfad9' : '#faf5fe')};
  color: #000;
  padding: 10px;
  border-radius: 10px;
  max-width: 80%;
  position: relative;
  font-size: 16px;
`;

const MessageText = styled.div`
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: 500;
`;

const MessageStatus = styled.div`
  color: #888;
  font-size: 12px;
  text-align: right;
`;

const MessageImage = styled.img`
  width: 100%;
  max-width: 300px;
  border-radius: 10px;
  margin-top: 10px;
`;

/*const MessageInputContainer = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background-color: white;
  margin-top: 2%;
`;*/

const MessageInputContainer = styled.div`
  width: 90vw;
  height: 60px;
  padding: 10px 10px; /* Уменьшаем боковые отступы */
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  background-color: #fff;
  border-top: 1px solid #e0e0e0;
  z-index: 1000;
`;

const PlusIcon = styled.div`
  font-size: 28px;
  margin-right: 10px;
  cursor: pointer;
`;

const MessageInput = styled.input`
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
  font-size: 16px;
  margin-right: 10px; /* Отступ справа от поля ввода */
`;

const SendButton = styled.img<{ disabled: boolean }>`
  width: 40px; /* Фиксированная ширина кнопки отправки */
  height: 40px;
  cursor: pointer;
  filter: ${({ disabled }) => (disabled ? 'grayscale(100%)' : 'none')};
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); // Затемнение фона
  display: flex;
  justify-content: center;
  align-items: flex-end; // Прижимаем модальное окно к низу
  z-index: 1000; // Высокий z-index для перекрытия всех элементов
`;

const ModalContent = styled.div`
  position: relative;
  width: 100%;
  background: linear-gradient(165.31deg, #6D33FF 11.36%, #612CE8 37.32%, #0E0033 99.31%);
  border-radius: 10px 10px 0 0;
  overflow: hidden; 
`;

const PaymentContainer = styled.div`
  position: absolute;  // Абсолютное позиционирование
  top: 0;              // Верх кнопки
  left: 0;             // Левый край кнопки
  width: 100%;         // Ширина кнопки
  height: 100%;        // Высота кнопки
  display: flex;       // Для выравнивания содержимого
  justify-content: center;
  align-items: center;
  z-index: 10;         // Расположить контейнер выше кнопки по z-индексу
`;

const PaymentContainerV = styled.div`
  position: absolute;  // Абсолютное позиционирование
  top: 0;              // Верх кнопки
  left: 0;             // Левый край кнопки
  width: 100%;         // Ширина кнопки
  height: 100%;        // Высота кнопки
  display: flex;       // Для выравнивания содержимого
  flex-direction: column; // Элементы будут располагаться по вертикали
  justify-content: center;
  align-items: center;
  z-index: 10;         // Расположить контейнер выше кнопки по z-индексу
`;

const InChat: React.FC<InChatProps> = ({ chat, onBack }) => {
  const [messages, setMessages] = useState<any[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [isModalTopup, setIsModalTopup] = useState(false)
  const stripePromise = loadStripe('pk_live_51PvIEsCUXAP9itNhSzAOu01dTlCRi7euhnyb8Faj7muKHI6dc72hQbOvTKQmBseO8RZRpHbeBMvOxEJJPvuBBVif00L0f4MtYm');
  const [purchasedCoinsonboard, setPurchasedCoinsonboard] = useState<number>(100);

  useEffect(() => {
    const fetchMessages = async () => {
      const userIdFromCookie = Cookies.get('userId');
      try {
        const response = await axios.post('https://golive.mobi/api/chat/sync2/', {
          id: userIdFromCookie,
          user2_id: chat.id,
          direction: 'up',
          timestamp: 0,
        }, {
          headers: {
            'Authorization': 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
            'Content-Type': 'application/json',
          },
        });
        setMessages(response.data.messages);
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };

    fetchMessages();
  }, [chat]);

  const formatTimestamp = (timestamp: number) => {
    const date = new Date(timestamp);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  const isMobilePlatform = () => {
    const userAgent = navigator.userAgent || navigator.vendor;
  
    // Проверяем Android
    if (/android/i.test(userAgent)) {
      return true;
    }
  
    // Проверяем iOS
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return true;
    }
  
    return false;
  };

  const fetchBalance = (userId: string) => {
    fetch('https://golive.mobi/api/coin/status/', {
      method: 'POST',
      headers: {
        Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id: userId }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success === 1) {
       
          // Сохраняем баланс в localStorage
          localStorage.setItem('balance', data.balance.toString());
          console.log('Balance updated');
        }
      })
      .catch(error => console.error('Error fetching balance:', error));
  };

  useEffect(() => {
    const userId = Cookies.get('userId');
    if (!userId) return;

    // Запускаем интервал для обновления баланса каждые 1.5 секунды
    const intervalId = setInterval(() => {
      fetchBalance(userId);
    }, 1500);

    // Очищаем интервал при размонтировании компонента
    return () => clearInterval(intervalId);
  }, []);

  const isMobile = isMobilePlatform(); // Определяем мобильное устройство

  const containerStyle = !isMobile ? { width: '25%', left: 'auto', right: 'auto' } : {};

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleSendMessage = async () => {
    const userIdFromCookie = Cookies.get('userId');
    if (!inputValue.trim()) return;
  
    const frms = parseInt(localStorage.getItem('frms') || '0', 10);
    const balance = parseInt(localStorage.getItem('balance') || '0', 10);
  
    if (frms > 0) {
      // Если frms больше 0, уменьшаем его на 1 и отправляем сообщение
      localStorage.setItem('frms', (frms - 1).toString());
  
      const newMessage = {
        id_from: userIdFromCookie,
        id_to: chat.id,
        message: inputValue,
        reply_id: '-1',
      };
  
      try {
        await axios.post('https://golive.mobi/api/chat/send/', [newMessage], {
          headers: {
            'Authorization': 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
            'Content-Type': 'application/json',
          },
        });
  
        setMessages([...messages, { ...newMessage, timestamp: Date.now() }]); // Добавляем новое сообщение в список
        setInputValue(''); // Очищаем поле ввода
      } catch (error) {
        console.error('Error sending message:', error);
      }
    } else {
      // Если frms меньше или равно 0, проверяем balance
      if (balance < 10) {
        setIsModalTopup(true); // Открываем модальное окно для пополнения
      } else {
        // Если balance >= 10, отправляем сообщение
        const newMessage = {
          id_from: userIdFromCookie,
          id_to: chat.id,
          message: inputValue,
          reply_id: '-1',
        };
  
        try {
          await axios.post('https://golive.mobi/api/chat/send/', [newMessage], {
            headers: {
              'Authorization': 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
              'Content-Type': 'application/json',
            },
          });
  
          setMessages([...messages, { ...newMessage, timestamp: Date.now() }]); // Добавляем новое сообщение в список
          setInputValue(''); // Очищаем поле ввода
        } catch (error) {
          console.error('Error sending message:', error);
        }
      }
    }
  };

  const handlePaymentSuccessPay2 = () => {
    setPurchasedCoinsonboard(900);
    logAmplitudeEvent('e.2.0.5 - Success pay', { component: 'e.2.0.5 - Success pay' });
    localStorage.setItem('DBB98', '9367');
    
    // Закрываем все модальные окна
    setIsModalTopup(false); 

  };

  const handlePaymentSuccessPay1 = () => {
    setPurchasedCoinsonboard(400);
    logAmplitudeEvent('e.2.0.5 - Success pay', { component: 'e.2.0.5 - Success pay' });
    localStorage.setItem('DBB98', '9367');
    
    setIsModalTopup(false); 
    
  };

  const handleClose = () => {
    localStorage.setItem('DBB98', '9367');
    setIsModalTopup(false);
  };


  return (
    <>
    <Container>
      <Header>
        <BackArrow src={inchatArrow} alt="Back" onClick={onBack} />
        <ProfileSection>
          <ProfileImage src={`https://golive.mobi${chat.avatar}`} alt="Profile" />
          <ProfileInfo>
            <ProfileName>{chat.name}, {chat.age}</ProfileName>
            <ProfileStatus>{chat.status === 'online' ? 'Online now' : 'Offline'}<StatusBadge /></ProfileStatus>
          </ProfileInfo>
        </ProfileSection>
       {/* <IconGroup>
          <Icon src={inchatTranslate} alt="Translate" />
          <Icon src={inchatVideo} alt="Video Call" />
          <Icon src={inchatMenu} alt="Menu" />
        </IconGroup> */}
      </Header>
      <ChatContainer>
        <DateSeparator>Today</DateSeparator>
        {messages.map((message, index) => (
          <Message key={index} isOwn={message.id_from === Cookies.get('userId')}>
            <MessageBubble isOwn={message.id_from === Cookies.get('userId')}>
              {message.message && <MessageText>{message.message}</MessageText>}
              {message.file_url && (
                <MessageImage src={`https://golive.mobi${message.file_url}`} alt="Image" />
              )}
              <MessageStatus>{formatTimestamp(message.timestamp)}</MessageStatus>
            </MessageBubble>
          </Message>
        ))}
      </ChatContainer>
      <MessageInputContainer>
       {/* <PlusIcon>+</PlusIcon> */}
        <MessageInput 
          type="text" 
          placeholder="Message..." 
          value={inputValue} 
          onChange={handleInputChange} 
        />
        <SendButton 
          src={sendbutton} 
          alt="Send" 
          disabled={!inputValue.trim()} 
          onClick={handleSendMessage}
        />
      </MessageInputContainer>
    </Container>


{ isModalTopup && (
  <ModalOverlay style={containerStyle}>
  <ModalContent style={{ backgroundColor: '#6D33FF', padding: '30px', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column' }}>
    {/* Изображение Topup прижато к верху и выровнено по центру */}
    <img 
      src={topup} 
      alt="Top Up" 
      style={{ width: '100%', height: 'auto', marginBottom: '20px', objectFit: 'contain' }} 
    />

<div 
style={{
  display: 'flex',
  justifyContent: 'center', // Центрируем контейнер по горизонтали
  width: '100%', // Контейнер занимает всю ширину
}}
>
<div
  style={{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '20px', // Расстояние между карточками
    maxWidth: '340px', // Фиксированная максимальная ширина для обеих карточек, чтобы оставались по центру
    flexWrap: 'wrap', // Позволяет карточкам переноситься на новую строку на малых экранах
  }}
>

<div style={{ position: 'relative' }}>
{/* Кнопка Continue */}
<img 
      src={payingcard1} 
      alt="Paying Card 1" 
      style={{ 
        width: '150px', // Регулируемый размер изображения
        height: 'auto', // Сохранение пропорций
      }} 

    />

{/* Контейнер для Stripe оплаты, накладывается поверх кнопки */}
<PaymentContainerV>
<div style={{ width: '150px', opacity: 0.01}}>
<Elements stripe={stripePromise}>
<Stripe
  label={`400 Coins`}
  amount={245} // Преобразование суммы в центы
  payment_charge_id={3} // Передаем payment_charge_id
  onPaymentSuccess={handlePaymentSuccessPay1}
/>
</Elements>
</div>

<div style={{ width: '150px', opacity: 0.01}}>
<Elements stripe={stripePromise}>
<Stripe
  label={`400 Coins`}
  amount={245} // Преобразование суммы в центы
  payment_charge_id={3} // Передаем payment_charge_id
  onPaymentSuccess={handlePaymentSuccessPay1}
/>
</Elements>
</div>

<div style={{ width: '150px', opacity: 0.01}}>
<Elements stripe={stripePromise}>
<Stripe
  label={`400 Coins`}
  amount={245} // Преобразование суммы в центы
  payment_charge_id={3} // Передаем payment_charge_id
  onPaymentSuccess={handlePaymentSuccessPay1}
/>
</Elements>
</div>
</PaymentContainerV>
</div>



<div style={{ position: 'relative' }}>
{/* Кнопка Continue */}
<img 
      src={payingcard2} 
      alt="Paying Card 2" 
      style={{ 
        width: '150px', // Регулируемый размер изображения
        height: 'auto', // Сохранение пропорций
      }} 
 
    />

{/* Контейнер для Stripe оплаты, накладывается поверх кнопки */}
<PaymentContainerV>
<div style={{ width: '150px', opacity: 0.01}}>
<Elements stripe={stripePromise}>
<Stripe
  label={`900 Coins`}
  amount={499} // Преобразование суммы в центы
  payment_charge_id={2} // Передаем payment_charge_id
  onPaymentSuccess={handlePaymentSuccessPay2}
/>
</Elements>
</div>

<div style={{ width: '150px', opacity: 0.01}}>
<Elements stripe={stripePromise}>
<Stripe
  label={`900 Coins`}
  amount={499} // Преобразование суммы в центы
  payment_charge_id={2} // Передаем payment_charge_id
  onPaymentSuccess={handlePaymentSuccessPay2}
/>
</Elements>
</div>

<div style={{ width: '150px', opacity: 0.01}}>
<Elements stripe={stripePromise}>
<Stripe
  label={`900 Coins`}
  amount={499} // Преобразование суммы в центы
  payment_charge_id={2} // Передаем payment_charge_id
  onPaymentSuccess={handlePaymentSuccessPay2}
/>
</Elements>
</div>
</PaymentContainerV>
</div>
</div>
</div>

<ContinueVipButton onClick={handleClose}>Continue</ContinueVipButton>

  </ModalContent>
</ModalOverlay>
)}

</>);
};

export default InChat;