import { record } from 'rrweb';

let events: any[] = [];
let isRecording = false;
let sessionIp: string | null = null;
let sessionFile: string | null = null;

// Функция для получения и сохранения IP-адреса клиента один раз
async function getClientIp() {
  if (!sessionIp) {
    try {
      const ipResponse = await fetch("https://ipapi.co/json");
      const ipData = await ipResponse.json();
      sessionIp = ipData.ip;
      console.log("IP клиента получен и сохранён:", sessionIp);
    } catch (error) {
      console.error("Ошибка при получении IP. Устанавливаем IP по умолчанию (0.0.0.0):", error);
      sessionIp = "0.0.0.0"; // Устанавливаем IP по умолчанию
    }
  }
  return sessionIp;
}

// Функция инициализации сессии с переданным IP
async function initializeSession() {
  await getClientIp();
  if (sessionIp) {
    try {
      const response = await fetch("https://apilive.golive.mobi/start-session", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ ip: sessionIp }),
      });
      const data = await response.json();
      sessionFile = data.session_file;
      console.log("Сессия начата, файл сессии:", sessionFile);
    } catch (error) {
      console.error("Ошибка при инициализации сессии:", error);
    }
  }
}

export function startRecording() {
  if (isRecording || !sessionFile) return;
  isRecording = true;

  record({
    emit(event) {
      events.push(event);
    },
  });

  // Отправляем события на сервер каждые 100 мс
  setInterval(() => {
    if (events.length > 0 && sessionIp) {
      sendEventsToServer(events);
      events = [];
    }
  }, 300);
}

// Функция для отправки событий на сервер
async function sendEventsToServer(events: any[]) {
  if (!sessionFile || !sessionIp) return;
  try {
    await fetch(`https://apilive.golive.mobi/save-event`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ip: sessionIp, events }),
    });
    console.log('События отправлены на сервер');
  } catch (error) {
    console.error('Ошибка при отправке событий:', error);
  }
}

// Инициализация сессии перед началом записи
initializeSession().then(() => {
  if (sessionFile) {
    startRecording();
  }
});