import React from 'react';
import styled from 'styled-components';
import paydone from '../res/paydone.webp';  // Убедитесь, что путь к изображению правильный.
import coinicon from '../res/coin.webp';  // Убедитесь, что путь к иконке монеты правильный.
import coindone from '../res/coindone.webp';  // Убедитесь, что путь к изображению coindone правильный.

interface CenterModalProps {
  onClose: () => void;
  purchasedCoins: number;  // Добавляем проп для купленных монет
}

// Затемненный фон для модального окна
const CenterModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1005;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// Стили для модального контента
const CenterModalContent = styled.div`
  background: linear-gradient(180deg, #6D33FF 0%, #612CE8 30%, #0E0033 100%);
  width: 90%;
  max-width: 500px;
  height: 50%; /* Устанавливаем высоту в 45% */
  border-radius: 20px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Элементы прижаты к верху */
  align-items: center;
  position: relative;
`;

// Изображение успеха оплаты
const Image = styled.img`
  width: 80%;
  height: auto;
  margin-top: 10px; /* Небольшой отступ сверху */
`;

// Контейнер для иконки монеты и текста
const CoinContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px; /* Отступ сверху для размещения блока под изображением */
`;

// Иконка монеты
const CoinIcon = styled.img`
  width: 50px;
  height: auto;
  margin-right: 10px;
`;

// Текст рядом с монетой
const CoinText = styled.span`
  font-size: 30px;
  font-weight: bold;
  color: white;
`;

// Изображение coindone
const CoinDoneImage = styled.img`
  width: 50%;
  height: auto;
  margin-top: 20px; /* Отступ сверху для размещения изображения */
`;

// Кнопка "OK"
const OkButton = styled.button`
  margin-top: auto; /* Прижимаем кнопку вниз */
  background: linear-gradient(90deg, #FCD900 0%, #FFDC0F 100%);
  border: none;
  border-radius: 30px;
  padding: 15px;
  width: 80%;
  color: black;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const isMobilePlatform = () => {
  const userAgent = navigator.userAgent || navigator.vendor;
  if (/android/i.test(userAgent)) return true;
  if (/iPad|iPhone|iPod/.test(userAgent)) return true;
  return false;
};

const isMobile = isMobilePlatform();
const containerStyle = !isMobile ? { width: '25%', left: 'auto', right: 'auto' } : {};

const CenterModal: React.FC<CenterModalProps> = ({ onClose, purchasedCoins }) => {
    return (
      <CenterModalOverlay style={containerStyle}>
        <CenterModalContent>
          <Image src={paydone} alt="Pay Done" />
          <CoinContainer>
            <CoinIcon src={coinicon} alt="Coin" />
            <CoinText>{purchasedCoins}</CoinText> {/* Отображаем купленные монеты */}
          </CoinContainer>
          <CoinDoneImage src={coindone} alt="Coin Done" />
          <OkButton onClick={onClose}>Ok</OkButton>
        </CenterModalContent>
      </CenterModalOverlay>
    );
};

export default CenterModal;