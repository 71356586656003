// src/index.tsx
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { startRecording } from './rrwebRecorder';
import {
  FpjsProvider
} from '@fingerprintjs/fingerprintjs-pro-react'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// Запускаем запись действий пользователя при загрузке
startRecording();

root.render(

        <FpjsProvider
      loadOptions={{
        apiKey: "zu1pGXmFWGQYPCC56AYB",
        region: "eu"
      }}
    >
    <App />
    </FpjsProvider>

);

// Регистрация сервис-воркера
serviceWorkerRegistration.register();
reportWebVitals();