import React, { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import Cookies from 'js-cookie';
import { io, Socket } from 'socket.io-client';
import axios from 'axios';
import EndCallIcon from '../res/EndCall.png';
import CallScreen from './CallScreen';
import priceprivate from '../res/priceprivate.png';

interface WaitPrivateCallProps {
  userData: {
    id: string;
    name: string;
    avatar: string;
    country: string;
    age: number;
    flag: string;
  };
  onCancel: () => void;
}

// Анимация для исчезающей цифры
const fadeOut = keyframes`
  0% {
    transform: scale(1) translateZ(0);
    opacity: 1;
  }
  100% {
    transform: scale(0.5) translateZ(-20px);
    opacity: 0;
  }
`;

// Анимация для новой появляющейся цифры
const fadeIn = keyframes`
  0% {
    transform: scale(1.5) translateZ(20px);
    opacity: 0;
  }
  100% {
    transform: scale(1) translateZ(0);
    opacity: 1;
  }
`;



const GradientBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(40,26,95,1) 30%, rgba(40,26,95,1) 70%, rgba(0,0,0,1) 100%);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  justify-content: flex-start;
  margin-top: 5vh;
`;

const avatarPulse = keyframes`
  0% {
    box-shadow: 0 0 0 40px rgba(0, 0, 0, 0.4);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
`;

const AvatarCircle = styled.div`
  width: 250px;
  height: 250px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  position: relative;
  animation: ${avatarPulse} 1s infinite ease-in-out;
`;

const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Counter = styled.div<{ animateOut: boolean }>`
  position: absolute;
  font-size: 56px;
  font-weight: bold;
  color: white;
  animation: ${({ animateOut }) => (animateOut ? fadeOut : fadeIn)} 0.5s ease forwards;
`;

const IconContainer = styled.div`
  position: absolute;
  bottom: 100px;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Icon = styled.img`
  width: 85px;
  height: 85px;
  cursor: pointer;
`;

const PrivateCallContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 40px;
  width: 100%;
  justify-content: center;
`;

const PrivateCallText = styled.div`
  font-size: 20px;
  color: white;
  font-weight: 600;
  text-align: center;
  margin-right: 10px;
`;

const PriceImage = styled.img`
  width: 30%;
`;

const ConnectingText = styled.div`
  font-size: 24px;
  color: white;
  text-align: center;
  margin-bottom: 20px;
`;

const NameText = styled.div`
  font-size: 26px;
  color: white;
  font-weight: bold;
  text-align: center;
  margin-top: 5px;
`;

const CountryAgeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
`;

const FlagImage = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 8px;
  border-radius: 50%;
`;

const CountryAgeText = styled.div`
  font-size: 18px;
  color: white;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Separator = styled.span`
  margin: 0 8px;
`;

const WaitPrivateCall: React.FC<WaitPrivateCallProps> = ({ userData, onCancel }) => {
  const socketRef = useRef<Socket | null>(null);
  const [dialogId, setDialogId] = useState<string | null>(null);
  const [counter, setCounter] = useState(1);
  const [animateOut, setAnimateOut] = useState(false);

  useEffect(() => {
    const userId = Cookies.get('userId');

    if (!userId) {
      console.error('User ID not found in cookies');
      return;
    }

    console.log(`Selected user ID: ${userData.id}`);

    socketRef.current = io('https://sckt.golive.mobi', {
      auth: { user_id: userId },
    });

    socketRef.current.on('connect', () => {
      console.log('Connected to socket');

      axios.post(
        'https://golive.mobi/api/feed/call/socket/',
        {
          user2_id: userData.id,
          id: userId,
        },
        {
          headers: {
            Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
            'Content-Type': 'application/json',
          },
        }
      )
      .then(response => {
        console.log('Call initiated successfully:', response.data);

        if (response.data.model_in_random === 0) {
          console.log('Model is in random, cancelling...');
          setDialogId(response.data.dialog_id.toString());
         // onCancel();
        } else {
          setDialogId(response.data.dialog_id.toString());
        }
      })
      .catch(error => {
        console.error('Error initiating call:', error);
      });
    });

    socketRef.current.on('disconnect', (reason) => {
      console.log('Socket disconnected:', reason);
    });

    socketRef.current.on('connect_error', (error: Error) => {
      console.error('Connection error:', error);
    });

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, [userData.id, onCancel]);

  // Таймер для счетчика
  useEffect(() => {
    const timer = setInterval(() => {
      setAnimateOut(true);

      setTimeout(() => {
        setCounter((prevCounter) => prevCounter + 1);
        setAnimateOut(false);
      }, 500);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  if (dialogId) {
    return (
      <CallScreen
        roomId={dialogId}
        onStoreEndCall={onCancel}
        userData={{ name: userData.name, avatar: `https://golive.mobi${userData.avatar}` }}
        onClose={onCancel}
        onTimerEndOrContinue={onCancel}
      />
    );
  }

  return (
    <GradientBackground>
      <TextContainer>
        <ConnectingText>Connecting</ConnectingText>
        <NameText>{userData.name}</NameText>
        <CountryAgeContainer>
          <FlagImage src={userData.flag} alt={`${userData.country} flag`} />
          <CountryAgeText>
            {userData.country}
            <Separator>•</Separator>
            Age: {userData.age}
          </CountryAgeText>
        </CountryAgeContainer>
      </TextContainer>
      <AvatarCircle>
        <AvatarImage src={`https://golive.mobi${userData.avatar}`} alt="Avatar" />
        <Counter animateOut={animateOut}>{counter}</Counter>
      </AvatarCircle>
      <IconContainer>
        <Icon src={EndCallIcon} alt="End Call" onClick={onCancel} />
      </IconContainer>
      <PrivateCallContainer>
        <PrivateCallText>Private Call</PrivateCallText>
        <PriceImage src={priceprivate} alt="Price" />
      </PrivateCallContainer>
    </GradientBackground>
  );
};

export default WaitPrivateCall;