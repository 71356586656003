import * as amplitude from '@amplitude/analytics-browser';

// Инициализация Amplitude
export const initializeAmplitude = (): void => {
  amplitude.init('2d648e96fd7f6ee923bde0c0bb0684b2', undefined, {
    defaultTracking: {
      sessions: true,
      pageViews: true,
    },
  });

  // Получаем IP-адрес и отправляем его на сервер вместо device_id
  fetchAndSendIpAsDeviceId();
};

// Функция для получения IP-адреса и отправки его на сервер как device_id
const fetchAndSendIpAsDeviceId = async () => {
  try {
    // Получаем IP-адрес с помощью сервиса ipapi
    const ipResponse = await fetch('https://ipapi.co/json');
    const ipData = await ipResponse.json();
    const ip = ipData.ip;

    if (ip) {
      console.log("IP Address as Device ID:", ip);
      // Отправляем IP-адрес на сервер для присвоения user_id
      sendDeviceIdToServer(ip);
    } else {
      console.warn("Не удалось получить IP-адрес.");
    }
  } catch (error) {
    console.error("Ошибка при получении IP-адреса:", error);
  }
};

// Функция для отправки IP-адреса на сервер и установки user_id
const sendDeviceIdToServer = async (device_id: string) => {
  try {
    const response = await fetch('https://apilive.golive.mobi/amplitude_tr', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ device_id }),  // отправляем IP как device_id
    });

    if (response.ok) {
      const data = await response.json();
      const user_id = data.user_id;

      if (user_id) {
        amplitude.setUserId(user_id);
        console.log("User ID set in Amplitude:", user_id);
      } else {
        console.warn("User ID not returned from server.");
      }
    } else {
      console.error("Failed to send IP address as device ID to server:", response.statusText);
    }
  } catch (error) {
    console.error("Error sending IP address as device ID to server:", error);
  }
};

// Функция для отправки событий
export const logAmplitudeEvent = (eventName: string, eventData?: Record<string, any>): void => {
  amplitude.track(eventName, eventData);
  console.log(`Event logged: ${eventName}`, eventData);


};

// Функция для установки пользовательских свойств
export const setAmplitudeUserProperties = (userProperties: Record<string, any>): void => {
  const identify = new amplitude.Identify();

  Object.keys(userProperties).forEach(key => {
    identify.set(key, userProperties[key]);
  });

  amplitude.identify(identify);
  console.log("User properties set:", userProperties);
};