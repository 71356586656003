import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import Cookies from 'js-cookie';
import storeIcon from '../res/lownavbar/store.webp';
import listIcon from '../res/lownavbar/list.webp';
import videochatIcon from '../res/lownavbar/videochat.webp';
import textchatIcon from '../res/lownavbar/textchat.webp';
import InChat from './InChat'; // Импортируем компонент InChat

interface ChatsProps {
  onStoreClick: () => void;
  onListClick: () => void;
  onVideoChatClick: () => void;
  onTextChatClick: () => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
`;

const NavBar = styled.div`
  width: 100%;
  height: 60px;
  padding: 10px 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  bottom: 0;
  background-color: #fff;
  border-top: 1px solid #e0e0e0;
  z-index: 1000;
`;

const NavItem = styled.img`
  width: 30px;
  height: 30px;
  cursor: pointer;
`;

const TabsContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
`;

const Tab = styled.div<{ active: boolean }>`
  font-size: 18px;
  font-weight: ${props => (props.active ? 'bold' : 'normal')};
  color: ${props => (props.active ? '#000' : '#888')};
  margin-right: 20px;
  cursor: pointer;
  position: relative;
  &::after {
    content: '';
    display: ${props => (props.active ? 'block' : 'none')};
    width: 100%;
    height: 2px;
    background-color: #6c63ff;
    position: absolute;
    bottom: -4px;
    left: 0;
  }
`;

const ChatList = styled.div`
  width: 100%;
  overflow-y: scroll;
`;

const ChatItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0px;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
`;

const ChatImage = styled.img`
  width: 70px;
  height: 70px;
  border-radius: 10px;
  margin-right: 15px;
`;

const ChatContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const ChatName = styled.div`
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-bottom: 6px; /* Небольшой отступ внизу */
`;

const ChatMessage = styled.div`
  font-size: 15px;
  color: #888;
  font-weight: 500;
`;

const StatusBadge = styled.div<{ color: string }>`
  background-color: ${props => props.color};
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-left: 7px;
  margin-bottom: 15px;

`;

const YourTurnBadge = styled.div`
  background: linear-gradient(to right, #ffcc00, #ff9900);
  background-size: 200% 100%;
  color: #fff;
  padding: 3px 8px;
  border-radius: 10px;
  font-size: 12px;
  margin-left: 10px;
  animation: gradientAnimation 4s ease-in-out infinite;

  @keyframes gradientAnimation {
    0% {
      background-position: 0% 0%;
    }
    50% {
      background-position: 100% 0%;
    }
    100% {
      background-position: 0% 0%;
    }
  }
`;

const ToggleButton = styled.div<{ selected: boolean }>`
  background-color: ${props => (props.selected ? '#fff7e0' : '#f7f5ff')};
  color: #000;
  font-size: 16px;
  padding: 5px 12px;
  border: 2px solid ${props => (props.selected ? '#ffd700' : '#ded9f7')};
  border-radius: 25px;
  text-align: center;
  font-weight: 500;
  cursor: pointer;
  margin-right: 10px;
`;

const Spacer = styled.div`
  height: 100px;
`;

const Chats: React.FC<ChatsProps> = ({ onStoreClick, onListClick, onVideoChatClick, onTextChatClick }) => {
  const [activeTab, setActiveTab] = useState<'messages' | 'calls'>('messages');
  const [chatData, setChatData] = useState<any[]>([]);
  const [selectedChat, setSelectedChat] = useState<any | null>(null); // Состояние для выбранного чата
  const userIdFromCookie = Cookies.get('userId');
  const [selectedButton, setSelectedButton] = useState<'all' | 'yourTurn'>('all');

  const isMobilePlatform = () => {
    const userAgent = navigator.userAgent || navigator.vendor;
  
    // Проверяем Android
    if (/android/i.test(userAgent)) {
      return true;
    }
  
    // Проверяем iOS
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return true;
    }
  
    return false;
  };

  const isMobile = isMobilePlatform(); // Определяем мобильное устройство

  // Применяем стили: если не мобильное устройство, ширина 25% и центрируем
  const containerStyle = !isMobile ? { width: '25%', left: 'auto', right: 'auto' } : {};

  const updateChatData = async () => {
    try {
      const response = await axios.post('https://golive.mobi/api/chat/pages/', {
        id: userIdFromCookie,
        filter: 'all',
      }, {
        headers: {
          'Authorization': 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
          'Content-Type': 'application/json',
        },
      });
      setChatData(response.data.list);
    } catch (error) {
      console.error('Error fetching chat data:', error);
    }
  };

  useEffect(() => {
    updateChatData(); // Загружаем список чатов при загрузке компонента

    const intervalId = setInterval(updateChatData, 5000); // Обновляем список чатов каждые 5 секунд

    return () => clearInterval(intervalId); // Очищаем интервал при размонтировании компонента
  }, []);

  const handleChatClick = (chat: any) => {
    setSelectedChat(chat); // Устанавливаем выбранный чат
  };

  if (selectedChat) {
    return <InChat chat={selectedChat} onBack={() => {
      setSelectedChat(null);
      updateChatData(); // Обновляем список чатов при возвращении назад
    }} />;
  }

  return (
    <Container>
      <TabsContainer>
        <Tab active={activeTab === 'messages'} onClick={() => setActiveTab('messages')}>
          Messages
        </Tab>
       {/* <Tab active={activeTab === 'calls'} onClick={() => setActiveTab('calls')}>
          Calls
        </Tab> */}
      </TabsContainer>

      <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px', marginBottom: '0px', width: '100%', justifyContent: 'flex-start' }}>
      <ToggleButton selected={selectedButton === 'all'} onClick={() => setSelectedButton('all')}>
        All
      </ToggleButton>
      <ToggleButton selected={selectedButton === 'yourTurn'} onClick={() => setSelectedButton('yourTurn')}>
        Your turn
      </ToggleButton>
    </div>

      {activeTab === 'messages' ? (
        <ChatList>
          {chatData.map((chat, index) => (
            <ChatItem key={index} onClick={() => handleChatClick(chat)}>
              <ChatImage src={`https://golive.mobi${chat.avatar}`} alt="Profile" />
              <ChatContent>
                <ChatName>
                  {chat.name}
                  <StatusBadge color={chat.status === 'online' ? 'rgb(0, 194, 0)' : 'red'} />
                  {chat.message?.id_from !== userIdFromCookie && <YourTurnBadge>Your turn</YourTurnBadge>}
                </ChatName>
                <ChatMessage>{chat.message?.message.slice(0, 40)}{chat.message?.message.length > 40 && '...'}</ChatMessage>
              </ChatContent>
            </ChatItem>
          ))}
          <Spacer />
        </ChatList>
      ) : (
        <ChatList>
          {/* Отобразить звонки, если есть. Аналогично можно обработать данные вызовов */}
          <Spacer />
        </ChatList>
      )}
      <NavBar style={containerStyle}>
        <NavItem src={storeIcon} alt="Store" onClick={onStoreClick} />
        <NavItem src={listIcon} alt="List" onClick={onListClick} />
        <NavItem src={videochatIcon} alt="Video Chat" onClick={onVideoChatClick} />
        <NavItem src={textchatIcon} alt="Text Chat" onClick={onTextChatClick} />
      </NavBar>
    </Container>
  );
};

export default Chats;