import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Cookies from 'js-cookie';
import storeIcon from '../res/lownavbar/store.webp';
import listIcon from '../res/lownavbar/list.webp';
import videochatIcon from '../res/lownavbar/videochat.webp';
import textchatIcon from '../res/lownavbar/textchat.webp';
import handtapstart from '../res/handtapstart.webp';
import heartIcon from '../res/heart.webp';
import globeIcon from '../res/globe.webp';
import popupclosebutton from '../res/popupclosebutton.webp';
import CoinIcon from '../res/coin.webp';
import startvip from '../res/start100.png';
import lastchance from '../res/lastchance.webp';
import freetrialsecond from '../res/freetrialsecond.webp'
import allowservices from '../res/allowservices.webp'
import allowservices2 from '../res/allowservices2.webp'
import ModalSuperMatch from './ModalSuperMatch';
import ModalCountry from './ModalCountry';
import allusersoff from '../res/allusersoff.webp'
import alluserson from '../res/alluserson.webp'
import premiumusersoff from '../res/premiumusersoff.webp'
import premiumuserson from '../res/premiumuserson.webp'
import strange_s from '../res/strange_s.webp'
import filterswitchoff from '../res/filterswitchoff.webp'
import filterswitchon from '../res/filterswitchon.webp'
import checkoff from '../res/checkoff.webp'
import checkon from '../res/checkon.webp'
import axios from 'axios';
import manycoins from '../res/manycoins.webp'
import paycongratulations from '../res/paycongratulations.webp'
import payingcard1 from '../res/payingcard1.webp'
import payingcard2 from '../res/payingcard2.webp'
import { RIGHT } from 'react-swipeable';
import ModalStore from './ModalStore'
import CenterModal from './CenterModal';
import { Elements } from '@stripe/react-stripe-js'; // Импортируем Elements из Stripe
import { loadStripe } from '@stripe/stripe-js'; // Импортируем loadStripe
import Stripe from '../components/Stripe'; // Импортируем твой компонент PaymentModal
import StripeReady from './StripeReady'
import topup from '../res/topup.webp'
import PWAInstall from '../components/PWAInstall'
import { logAmplitudeEvent } from '../amplitude';
import f_signpic from '../res_land/f_signpic.webp'
import headerinstallpopup from '../res_install/headerinstallpopup.webp'
import checkboxoffinstallpopup from '../res_install/checkboxoffinstallpopup.webp'
import checkboxoninstallpopup from '../res_install/checkboxoninstallpopup.webp'
import installappinstallpopup from '../res_install/installappinstallpopup.webp'
import camerainstallpopup from '../res_install/camerainstallpopup.webp'
import microphoneinstallpopup from '../res_install/microphoneinstallpopup.webp'
import { Oval } from 'react-loader-spinner';
import Checkbot from './checkbot'

import hand_v2 from '../res_v2/hand_mainscreen.png'
import circle_v2 from '../res_v2/circle_mainscreen.png'

// Создаем Stripe объект с твоим публичным ключом
const stripePromise = loadStripe('pk_live_51PvIEsCUXAP9itNhSzAOu01dTlCRi7euhnyb8Faj7muKHI6dc72hQbOvTKQmBseO8RZRpHbeBMvOxEJJPvuBBVif00L0f4MtYm');

interface RandomCallProps {
  onMatchClick: () => void;
  onStoreClick: () => void;
  onStoreFreeCallClick: () => void;
  onListClick: () => void;
  onChatsClick: () => void;
  showModal: boolean; // Новый пропс для управления видимостью модального окна
}

interface ModalProps {
  onClose: () => void;
  balance: number;
  amount: number;
  paymentChargeId?: number;  // Сделали опциональным
  onPaymentSuccessAndClose: () => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100vh; 
  box-sizing: border-box;
  overflow: hidden;
  position: fixed;
  bottom: 0%;
  z-index: 5;
`;

const GradientBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(40,26,95,1) 30%, rgba(40,26,95,1) 70%, rgba(0,0,0,1) 100%);
  z-index: 0;
  overflow: hidden;
`;

const CentralText = styled.div`
  font-size: 20px;
  color: #fff;
  text-align: center;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  font-weight: 600;
`;

const OnlineUsers = styled.div`
  font-size: 35px;
  color: #fff;
  margin-top: 15vh;
  font-weight: bold;
  text-align: center;
`;

const CircleImage = styled.img`
  width: 270px;
  height: 270px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  animation: pulse 2.5s ease-in-out infinite;

  @keyframes pulse {
    0%, 100% {
      transform: translate(-50%, -50%) scale(1); /* Исходный размер */
    }
    50% {
      transform: translate(-50%, -50%) scale(0.80); /* Уменьшение */
    }
  }
`;

const HandTapStartImage = styled.img`
  width: 95px;
  height: 95px;
  position: absolute;
  top: 48%;
  left: 46%;
  transform: translate(-50%, -50%);
  z-index: 2; /* На слой выше CircleImage */
`;

const MatchButtonContainer = styled.div`
  position: absolute;
  bottom: 8vh;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 60px); 
  z-index: 2;
`;

const MatchButton = styled.button`
  position: relative;
  width: 100%;
  background-color: #FCD900; 
  color: black;
  font-size: 20px; 
  font-weight: bold;
  padding: 18px 0; 
  border: none;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  white-space: nowrap; 
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e5c838;
  }
`;

const FreeBadge = styled.div`
  position: absolute;
  top: -20px;
  right: -1px;
  background-color: #00C013;
  color: white;
  font-size: 17px;
  font-weight: bold;
  padding: 7px 22px;
  border-radius: 20px;
  border-bottom-left-radius: 0px;
`;

const OptionButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px; 
  position: absolute;
  bottom: 20vh; 
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 60px);
  z-index: 2;
`;

const OptionButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4B2A7B;
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 30px;
  width: 48%; 
`;

const OptionIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 10px;
`;

const NavBar = styled.div`
  width: 100%;
  height: 60px;
  padding: 10px 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
`;

const NavItem = styled.img`
  width: 30px;
  height: 30px;
  cursor: pointer;
`;

// Стилизованный компонент для модального окна с затемнением фона
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); // Затемнение фона
  display: flex;
  justify-content: center;
  align-items: flex-end; // Прижимаем модальное окно к низу
  z-index: 1000; // Высокий z-index для перекрытия всех элементов
`;

const ModalContent = styled.div`
  position: relative;
  width: 100%;
  background: linear-gradient(165.31deg, #6D33FF 11.36%, #612CE8 37.32%, #0E0033 99.31%);
  border-radius: 10px 10px 0 0;
  overflow: hidden; 
`;

const ModalContentSecond = styled.div`
  position: relative;
  width: 100%;
  background: linear-gradient(165.31deg, #6D33FF 11.36%, #612CE8 37.32%, #0E0033 99.31%);
  border-radius: 10px 10px 0 0;
  overflow: hidden; 
`;

const HeaderContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center; 
  padding: 15px 0; // Отступ сверху для кнопки и текста
  width: 100%;
`;

const CloseButton = styled.img`
  position: absolute;
  left: 20px; // Позиционирование кнопки от левого края
  top: 15px; // Позиционирование кнопки от верха
  width: 36px;
  height: 36px;
  cursor: pointer;
`;

const WelcomeText = styled.div`
  font-size: 25px;
  color: #FFFFFF; 
  font-weight: bold;
  margin-top: 5px; // Отступ от верха на том же уровне, что и у CloseButton
  text-align: center;
  flex-grow: 1; // Заполняет оставшееся пространство для центрирования текста
`;

const CountdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  background-color: rgba(0, 0, 0, 0.1); // Цвет с прозрачностью 10%
  padding: 15px 25px;
  border-radius: 20px;
  max-width: 200px; // Уменьшенная ширина контейнера
  margin-left: auto;
  margin-right: auto;
`;

const CountdownText = styled.div`
  font-size: 16px;
  color: #FFFFFF; // Белый цвет без прозрачности для текста "Countdown"
  margin-bottom: 5px;
`;

const Timer = styled.div`
  display: flex;
  align-items: center;
`;

const Time = styled.div<{ isHighlighted?: boolean }>`
  font-size: 24px;
  color: ${({ isHighlighted }) => (isHighlighted ? '#FFFFFF' : 'rgba(255, 255, 255, 0.4)')};
  font-weight: bold;
`;

const Separator = styled.div`
  font-size: 24px;
  color: rgba(255, 255, 255, 0.4); // Белый с прозрачностью 40% для разделителей
  font-weight: bold;
  margin: 0 5px;
`;

const StartVipImage = styled.img`
  margin-top: 20px;
  width: 300px; // Увеличенная ширина изображения
  height: auto; // Автоматическая высота, чтобы сохранить пропорции
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const ContinueVipButton = styled.button`
  margin-top: 20px;
  width: 300px; // Ширина кнопки
  background-color: #FCD900; 
  color: black;
  font-size: 20px; 
  font-weight: bold;
  padding: 15px 0; 
  border: none;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: background-color 0.3s ease;
  margin-left: auto;
  margin-right: auto;
  display: block; // Центрирование кнопки
`;

const AgreementText = styled.div`
  margin-top: 15px;  // Отступ сверху от кнопки "Continue"
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5); // Белый цвет с прозрачностью 50%
  text-align: center;
  max-width: 300px; // Ширина текста, такая же как у кнопки "Continue"
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;  // Отступ снизу до края модального окна

  a {
    color: rgba(255, 255, 255, 0.5);  // Сохраняем цвет текста
    text-decoration: underline;  // Добавляем подчеркивание для ссылок
    cursor: pointer;

    &:hover {
      text-decoration: none;  // Убираем подчеркивание при наведении
    }
  }
`;

const CenteredModalOverlay = styled(ModalOverlay)`
  align-items: center; // Изменяем выравнивание по вертикали на центр
`;

// Стили для CoinsContainer, CoinText, и CoinImage
const CoinsContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 20px;
`;

const CoinText = styled.span`
  font-size: 16px;
  font-weight: bold;
  margin-right: 5px;
  color: white;  // Цвет текста белый для лучшей видимости
`;

const CoinImage = styled.img`
  width: 30px;
  height: 30px;
`;

const PaymentContainer = styled.div`
  position: absolute;  // Абсолютное позиционирование
  top: 0;              // Верх кнопки
  left: 0;             // Левый край кнопки
  width: 100%;         // Ширина кнопки
  height: 100%;        // Высота кнопки
  display: flex;       // Для выравнивания содержимого
  justify-content: center;
  align-items: center;
  z-index: 10;         // Расположить контейнер выше кнопки по z-индексу
`;

const PaymentContainerV = styled.div`
  position: absolute;  // Абсолютное позиционирование
  top: 0;              // Верх кнопки
  left: 0;             // Левый край кнопки
  width: 100%;         // Ширина кнопки
  height: 100%;        // Высота кнопки
  display: flex;       // Для выравнивания содержимого
  flex-direction: column; // Элементы будут располагаться по вертикали
  justify-content: center;
  align-items: center;
  z-index: 10;         // Расположить контейнер выше кнопки по z-индексу
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px; // Расстояние между кнопками
  margin-top: 20px;
`;

const DisclaimerText = styled.p`
  font-size: 14px;
  color: white;
  text-align: center;
  margin-top: 20px;
  padding: 0 20px; // Для отступов по бокам
  line-height: 1.4;

  a {
    color: #FFD700;
    text-decoration: underline;
  }
`;

const ModalImage = styled.img`
  margin-top: 20px; // Отступ сверху
  width: 80%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

interface Country {
  code: string;
  flag: string;
  name: string;
}

const RandomCall: React.FC<RandomCallProps & { showModal: boolean }> = ({ onMatchClick, onListClick, onStoreClick, onStoreFreeCallClick, onChatsClick, showModal }) => {
  const [userId, setUserId] = useState<string>('');
  /*const [isModalOpen, setIsModalOpen] = useState<boolean>(showModal && !Cookies.get('modalsDismissed'));*/ // Используем куки, чтобы контролировать показ первого модального окна
  /*const [isModalOpen, setIsModalOpen] = useState(true);*/
  const [isModalOpen, setIsModalOpen] = useState<boolean>(!localStorage.getItem('DBB98'));
  const [isModalOpenAuth, setIsModalOpenAuth] = useState(false);
  const [isSecondModalOpen, setIsSecondModalOpen] = useState<boolean>(false); // Состояние для второго модального окна
  const [isThirdModalOpen, setIsThirdModalOpen] = useState<boolean>(false); // Состояние для третьего модального окна
  const [secondTimer, setSecondTimer] = useState(1800); // 1800 секунд = 30 минут
  const [isSuperMatchModalOpen, setIsSuperMatchModalOpen] = useState<boolean>(false);
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<'all' | 'premium'>('all'); // Default state is 'all'
  const [isCountryModalOpen, setIsCountryModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<'balanced' | 'global' | null>(null);
  const [countries, setCountries] = useState<Country[]>([]); // Список стран
  const [selectedCountry, setSelectedCountry] = useState<string | null>(null); // Выбранная страна
  const [isThirdModalEmptyOpen, setIsThirdModalEmptyOpen] = useState<boolean>(false);
  const [balance, setBalance] = useState<number | null>(null);
  const [welcomeTimer, setWelcomeTimer] = useState(30);
  const [isModalStoreOpen, setModalStoreOpen] = useState(false);
  const [isModalStoreOpen2, setModalStoreOpen2] = useState(false);
  const [isCenterModalOpen, setIsCenterModalOpen] = useState<boolean>(false);
  const [isCenterModalOpen2, setIsCenterModalOpen2] = useState<boolean>(false);
  const [purchasedCoinsonboard, setPurchasedCoinsonboard] = useState<number>(100); // Здесь будет отображаться количество купленных монет.
  const [isPurpleModalOpen, setIsPurpleModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  const [hasError, setHasError] = useState(false);

 {/*} useEffect(() => {
    // Проверяем наличие ключа в localStorage
    const authModalShown = localStorage.getItem('authModalShown');
    
    if (isIOS && !authModalShown) {
      // Если это iOS и ключа нет, показываем модальное окно авторизации
      setIsModalOpenAuth(true);
    }
  }, [isIOS]); */}

    // Состояния для каждого checkbox
    const [isAppChecked, setIsAppChecked] = useState(false);
    const [isCameraChecked, setIsCameraChecked] = useState(false);
    const [isMicrophoneChecked, setIsMicrophoneChecked] = useState(false);
  
    // Функции для переключения состояний
    const toggleAppCheckbox = () => setIsAppChecked(!isAppChecked);
    const toggleCameraCheckbox = () => setIsCameraChecked(!isCameraChecked);
    const toggleMicrophoneCheckbox = () => setIsMicrophoneChecked(!isMicrophoneChecked);

  const [isInstallApp, setIsInstallApp] = useState<boolean>(false); // Состояние для третьего модального окна



  const [stripePromise, setStripePromise] = useState(() => loadStripe('pk_live_51PvIEsCUXAP9itNhSzAOu01dTlCRi7euhnyb8Faj7muKHI6dc72hQbOvTKQmBseO8RZRpHbeBMvOxEJJPvuBBVif00L0f4MtYm'))

  const PaymentInitializerWrapper = () => (
    <Elements stripe={stripePromise}>
      <StripeReady />
    </Elements>
  );

  useEffect(() => {
    logAmplitudeEvent('e.2.0 - Main Screen', { component: 'e.2.0 - Main Screen' });
  }, []);

  const handlePaymentSuccess = () => {
    setIsModalOpen(false); // Закрываем текущее модальное окно
    setIsThirdModalEmptyOpen(true); // Открываем новое модальное окно
  };

  const isMobilePlatform = () => {
    const userAgent = navigator.userAgent || navigator.vendor;
  
    // Проверяем Android
    if (/android/i.test(userAgent)) {
      return true;
    }
  
    // Проверяем iOS
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return true;
    }
  
    return false;
  };

  const isMobile = isMobilePlatform(); // Определяем мобильное устройство

  // Применяем стили: если не мобильное устройство, ширина 25% и центрируем
  const containerStyle = !isMobile ? { width: '25%', left: 'auto', right: 'auto' } : {};

  useEffect(() => {
      const userId = Cookies.get('userId');
      axios.post('https://golive.mobi/api/search/countrylist/', {
        id: userId,
      }, {
        headers: {
          'Authorization': 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
          'Content-Type': 'application/json',
        },
      })
      .then(response => {
        setCountries(response.data.list);
      })
      .catch(error => {
        console.error('Ошибка при получении списка стран:', error);
      });

  }, [isCountryModalOpen]);

  useEffect(() => {
    const preloadImage = (src: string) => {
      return new Promise<void>((resolve, reject) => {
        const img = new Image();
        img.src = src;
        img.onload = () => resolve();
        img.onerror = () => reject(new Error(`Failed to load image: ${src}`));
      });
    };
  
    const loadCountriesWithFlags = async () => {
      const userId = Cookies.get('userId');
      if (userId) {
        try {
          const response = await axios.post(
            'https://golive.mobi/api/search/countrylist/',
            { id: userId },
            {
              headers: {
                'Authorization': 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
                'Content-Type': 'application/json',
              },
            }
          );
  
          const countriesData = response.data.list;
          const promises = countriesData.map((country: Country) => preloadImage(country.flag));
  
          // Ожидаем загрузку всех флагов
          await Promise.all(promises);
          setCountries(countriesData);
        } catch (error) {
          console.error('Ошибка при получении списка стран или загрузке флагов:', error);
        }
      }
    };
  
    loadCountriesWithFlags();
  }, []); // Этот эффект срабатывает один раз при монтировании компонента

// Функция для шифрования данных
const encryptData = (data: string, key: string) => {
  const encryptedData = btoa(`${key}:${data}`);
  return encryptedData;
};

// Функция для расшифровки данных
const decryptData2 = (ciphertext: string, key: string) => {
  try {
    const decodedData = atob(ciphertext);
    const [decodedKey, decodedValue] = decodedData.split(':');
    if (decodedKey === key) {
      return decodedValue;
    }
    return null;
  } catch (error) {
    console.error('Ошибка расшифровки данных:', error);
    return null;
  }
};

// Ключ для шифрования и дешифровки
const fetchBalance = (userId: string) => {
  fetch('https://golive.mobi/api/coin/status/', {
    method: 'POST',
    headers: {
      Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ id: userId }),
  })
    .then(response => response.json())
    .then(data => {
      if (data.success === 1) {
        setBalance(data.balance);

        // Сохраняем баланс напрямую в localStorage
        localStorage.setItem('balance', data.balance.toString());
        console.log('wr bl')
      }
    })
    .catch(error => console.error('Error fetching balance:', error));
};

useEffect(() => {
  const userIdFromCookie = Cookies.get('userId');
  if (userIdFromCookie && userId !== userIdFromCookie) {
    setUserId(userIdFromCookie);
  }

  if (userIdFromCookie) {
    fetchBalance(userIdFromCookie);
    const intervalId = setInterval(() => fetchBalance(userIdFromCookie), 1000);
    return () => clearInterval(intervalId); // Очищаем интервал при размонтировании компонента
  }
}, [userId]);



  const handleAllUsersClick = () => {
    setSelectedFilter('all');
  };

  const handlePremiumUsersClick = () => {
    setSelectedFilter('premium');
  };

  const handleSwitchClick = () => {
    setIsSwitchOn(!isSwitchOn);
  };

  const handleGlobalButtonClick = () => {
    setIsCountryModalOpen(true);
};

  useEffect(() => {
    const userIdFromCookie = Cookies.get('userId');
    setUserId(userIdFromCookie || '');
  }, []);

  const handleGirlButtonClick = () => {
    setIsSuperMatchModalOpen(true);
  };

  const handleMatchClick = () => {
    // Получаем значение randomCalls из localStorage
    const randomCallsValue = localStorage.getItem('randomCalls');
    const randomCalls = randomCallsValue ? parseInt(randomCallsValue, 10) : 0;
    logAmplitudeEvent('e.2.1 - Start search', { component: 'e.2.1 - Start search' });
    // Получаем значение баланса напрямую (предположим, что баланс хранится как число в localStorage)
    const balanceValue = localStorage.getItem('balance');
    const balance = balanceValue ? parseInt(balanceValue, 10) : 0;
  
    // Проверяем баланс и randomCalls перед продолжением
    if (balance < 20 && randomCalls < 1) {
      setIsPurpleModalOpen(true); // Если условий не выполнены, показываем модальное окно
      logAmplitudeEvent('e.2.0.1 - Out of balance', { component: 'e.2.0.1 - Out of balance' });
    } else {
      // Если условия выполнены, проверяем доступ к камере и микрофону и продолжаем
      navigator.permissions.query({ name: 'camera' as PermissionName })
        .then(permissionStatus => {
          if (permissionStatus.state === 'prompt') {
            setIsThirdModalOpen(true); // Если требуется разрешение на камеру
            logAmplitudeEvent('e.2.0.2 - Request access', { component: 'e.2.0.2 - Request access' });
          } else {
            navigator.permissions.query({ name: 'microphone' as PermissionName })
              .then(micPermissionStatus => {
                if (micPermissionStatus.state === 'prompt') {
                  setIsThirdModalOpen(true); // Если требуется разрешение на микрофон
                  logAmplitudeEvent('e.2.0.2 - Request access', { component: 'e.2.0.2 - Request access' });
                } else {
                  onMatchClick(); // Если все права предоставлены, продолжаем с Random Match
                }
              });
          }
        });
    }
  };

  const PurpleModal = () => {
    return (
      <ModalOverlay style={containerStyle}>
        <ModalContent style={{ backgroundColor: '#6D33FF', padding: '30px', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column' }}>
          {/* Изображение Topup прижато к верху и выровнено по центру */}
          <img 
            src={topup} 
            alt="Top Up" 
            style={{ width: '100%', height: 'auto', marginBottom: '20px', objectFit: 'contain' }} 
          />

<div 
  style={{
    display: 'flex',
    justifyContent: 'center', // Центрируем контейнер по горизонтали
    width: '100%', // Контейнер занимает всю ширину
  }}
>
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '20px', // Расстояние между карточками
      minWidth: '350px', // Фиксированная максимальная ширина для обеих карточек, чтобы оставались по центру
      flexWrap: 'wrap', // Позволяет карточкам переноситься на новую строку на малых экранах
    }}
  >

<div style={{ position: 'relative' }}>
  {/* Кнопка Continue */}
  <img 
            src={payingcard1} 
            alt="Paying Card 1" 
            style={{ 
              width: '150px', // Регулируемый размер изображения
              height: 'auto', // Сохранение пропорций
            }} 
      
          />

  {/* Контейнер для Stripe оплаты, накладывается поверх кнопки */}
  <PaymentContainerV>
  <div style={{ width: '150px', opacity: 0.01}}>
    <Elements stripe={stripePromise}>
      <Stripe
        label={`400 Coins`}
        amount={245} // Преобразование суммы в центы
        payment_charge_id={3} // Передаем payment_charge_id
        onPaymentSuccess={handlePaymentSuccessPay1}
      />
    </Elements>
    </div>

  <div style={{ width: '150px', opacity: 0.01}}>
    <Elements stripe={stripePromise}>
      <Stripe
        label={`400 Coins`}
        amount={245} // Преобразование суммы в центы
        payment_charge_id={3} // Передаем payment_charge_id
        onPaymentSuccess={handlePaymentSuccessPay1}
      />
    </Elements>
    </div>

  <div style={{ width: '150px', opacity: 0.01}}>
    <Elements stripe={stripePromise}>
      <Stripe
        label={`400 Coins`}
        amount={245} // Преобразование суммы в центы
        payment_charge_id={3} // Передаем payment_charge_id
        onPaymentSuccess={handlePaymentSuccessPay1}
      />
    </Elements>
    </div>
  </PaymentContainerV>
</div>



<div style={{ position: 'relative' }}>
  {/* Кнопка Continue */}
  <img 
            src={payingcard2} 
            alt="Paying Card 2" 
            style={{ 
              width: '150px', // Регулируемый размер изображения
              height: 'auto', // Сохранение пропорций
            }} 
       
          />

  {/* Контейнер для Stripe оплаты, накладывается поверх кнопки */}
  <PaymentContainerV>
  <div style={{ width: '150px', opacity: 0.01}}>
    <Elements stripe={stripePromise}>
      <Stripe
        label={`900 Coins`}
        amount={499} // Преобразование суммы в центы
        payment_charge_id={2} // Передаем payment_charge_id
        onPaymentSuccess={handlePaymentSuccessPay2}
      />
    </Elements>
    </div>

    <div style={{ width: '150px', opacity: 0.01}}>
    <Elements stripe={stripePromise}>
      <Stripe
        label={`900 Coins`}
        amount={499} // Преобразование суммы в центы
        payment_charge_id={2} // Передаем payment_charge_id
        onPaymentSuccess={handlePaymentSuccessPay2}
      />
    </Elements>
    </div>

    <div style={{ width: '150px', opacity: 0.01}}>
    <Elements stripe={stripePromise}>
      <Stripe
        label={`900 Coins`}
        amount={499} // Преобразование суммы в центы
        payment_charge_id={2} // Передаем payment_charge_id
        onPaymentSuccess={handlePaymentSuccessPay2}
      />
    </Elements>
    </div>
  </PaymentContainerV>
</div>
</div>

</div>


<ContinueVipButton onClick={handleClosePurple}>Continue</ContinueVipButton>

        </ModalContent>
      </ModalOverlay>
    );
  };

  useEffect(() => {
    if (isModalOpen && !localStorage.getItem('DBB98') && welcomeTimer > 0) {
      const intervalId = setInterval(() => {
        setWelcomeTimer((prevTime) => prevTime - 1);
      }, 1000);
  
      if (welcomeTimer === 0) {
        setIsModalOpen(false);
        setIsSecondModalOpen(true);
      }
  
      return () => clearInterval(intervalId);
    }
  }, [isModalOpen, welcomeTimer]);

  useEffect(() => {
    if (isSecondModalOpen && secondTimer > 0) {
      const intervalId = setInterval(() => {
        setSecondTimer(prevTimer => prevTimer - 1);
      }, 1000);

      return () => clearInterval(intervalId);
    } else if (isSecondModalOpen && secondTimer === 0) {
      setIsSecondModalOpen(false);
      localStorage.setItem('DBB98', '9367'); // Сохранение в localStorage
    }
  }, [isSecondModalOpen, secondTimer]);

  const formatTimeWithHours = (time: number) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;
    return `${hours.toString().padStart(2, '0')} : ${minutes.toString().padStart(2, '0')} : ${seconds.toString().padStart(2, '0')}`;
  };

  const formatTime = (time: number) => {
    return time.toString().padStart(2, '0');
  };

  const handleClose = () => {
    localStorage.setItem('DBB98', '9367');
    setIsModalOpen(false);
    setIsSecondModalOpen(true);
  };

 {/* useEffect(() => {
    // Получаем значение ключа 'pwainstall' из localStorage
    const isPwaInstall = localStorage.getItem('pwainstall') === 'true';
    
    // Если нет ключа 'DBB98' и 'pwainstall' установлен как 'true', показываем модальное окно
    if (!localStorage.getItem('DBB98') && isPwaInstall) {
      setIsModalOpen(true);  // Показываем окно
      logAmplitudeEvent('e.2.0.3 - Welcome bonus', { component: 'e.2.0.3 - Welcome bonus' });
    }
    
    // Если 'pwainstall' установлен как 'false', запускаем установку приложения
    if (localStorage.getItem('pwainstall') === 'false') {
      setIsInstallApp(true);  // Запускаем установку приложения
      logAmplitudeEvent('e.2.0.4 - Popup install PWA and premissions', { component: 'e.2.0.4 - Popup install PWA and premissions' });
    }
  }, []); */}

  useEffect(() => {
    const isPwaInstall = localStorage.getItem('pwainstall') === 'true';
    
    // Функция для проверки iOS
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  
    if (isIOS) {
      // Если это iOS, проверяем наличие 'DBB98' в localStorage
      if (!localStorage.getItem('DBB98')) {
        setIsModalOpen(true); // Показываем модальное окно для iOS
        logAmplitudeEvent('e.2.0.3 - Welcome bonus', { component: 'e.2.0.3 - Welcome bonus' });
      }
    } else {
      // Если не iOS, проверяем значение ключа 'pwainstall' и 'DBB98' в localStorage
      if (!localStorage.getItem('DBB98') && isPwaInstall) {
        setIsModalOpen(true); // Показываем окно приветствия
        logAmplitudeEvent('e.2.0.3 - Welcome bonus', { component: 'e.2.0.3 - Welcome bonus' });
      }
  
      if (localStorage.getItem('pwainstall') === 'false88474') {
        setIsInstallApp(true); // Запускаем установку PWA для других платформ
        logAmplitudeEvent('e.2.0.4 - Popup install PWA and permissions', { component: 'e.2.0.4 - Popup install PWA and permissions' });
      }
    }
  }, []);

  const handlePaying = () => {
    setIsThirdModalEmptyOpen(false);
  };

  const handleContinueClick = () => {
    setIsSecondModalOpen(false); // Закрываем текущее окно
    setIsThirdModalEmptyOpen(true); // Открываем новое пустое окно
  };

  const handleAllowAll = () => {
    navigator.mediaDevices.getUserMedia({ video: true, audio: true })
      .then(stream => {
        // Handle successful permission granting
        logAmplitudeEvent('e.1.0.5 - Premissions access', { component: 'e.1.0.5 - Premissions access' });
        console.log('Permissions granted');
        // Close the third modal and proceed with the application flow
        setIsThirdModalOpen(false);
        onMatchClick(); // Proceed to the Random Match after granting permissions
      })
      .catch(error => {
        // Handle errors or permission denial
        console.log('Permissions denied:', error);
        logAmplitudeEvent('e.1.0.5 - Premissions denied', { component: 'e.1.0.5 - Premissions denied' });
        // You might want to show an error message or take some other action
      });
  };

  const handleBalancedCheckboxClick = () => {
    setSelectedOption('balanced');
    setSelectedCountry(null); // Сбрасываем выбор страны
  };
  
  const handleGlobalCheckboxClick = () => {
    setSelectedOption('global');
    setSelectedCountry(null); // Сбрасываем выбор страны
  };
  
  const handleCountryClick = (countryCode: string) => {
    setSelectedCountry(countryCode);
    setSelectedOption(null); // Сбрасываем выбор Balanced или Global
  };

  const handleUniversalContinueClick = () => {
    localStorage.setItem('DBB98', '9367');
    // Закрываем оба модальных окна, если они открыты
    setIsSecondModalOpen(false);
    setIsModalOpen(false);
  
    // Открываем пустое модальное окно
    setIsThirdModalEmptyOpen(true);
  };

  useEffect(() => {
    const userIdFromCookie = Cookies.get('userId');
    setUserId(userIdFromCookie || '');
  
    if (userIdFromCookie) {
      fetch('https://golive.mobi/api/coin/status/', {
        method: 'POST',
        headers: {
          'Authorization': 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: userIdFromCookie }),
      })
      .then(response => response.json())
      .then(data => {
        if (data.success === 1) {
          setBalance(data.balance);
        }
      })
      .catch(error => console.error('Error fetching balance:', error));
    }
  }, []);

// Пример логики для отображения FreeBadge с условием
const randomCallsValue = localStorage.getItem('randomCalls'); // Предположим, значение хранится в localStorage
const randomCalls = randomCallsValue ? parseInt(randomCallsValue, 10) : 0;

// Логика для отображения FreeBadge
// Функции для шифрования и дешифровки
const decryptData = (ciphertext: string, key: string) => {
  try {
    const decodedData = atob(ciphertext);
    const [decodedKey, decodedValue] = decodedData.split(':');
    if (decodedKey === key) {
      return decodedValue;
    }
    return null;
  } catch (error) {
    console.error('Ошибка расшифровки данных:', error);
    return null;
  }
};

const FreeBadgeContent = () => {
  // Чтение значения randomCalls из localStorage
  const randomCallsValue = localStorage.getItem('randomCalls');
  const randomCalls = randomCallsValue ? parseInt(randomCallsValue, 10) : 0;

  if (randomCalls > 0) {
    return "Free"; // Если randomCalls больше 1, показываем Free
  } else {
    return (
      <>
        5 <img src={CoinIcon} alt="Coin" style={{ width: '15px' }} />/min
      </>
    ); // Если randomCalls 0 или его нет, показываем 5 монет/мин
  }
};

const handleOpenMStore = () => {
  setModalStoreOpen(true);      // Открываем ModalStore
  console.log('setModalStoreOpen')
};

const handlePaymentSuccessStripe = () => {
  console.log('success pay in stripe');
  logAmplitudeEvent('e.2.0.5 - Success pay', { component: 'e.2.0.5 - Success pay' });
  localStorage.setItem('DBB98', '9367');
  localStorage.setItem('paymaster', 'ja-s')
  // Закрываем все модальные окна
  setIsModalOpen(false); 
  setIsSecondModalOpen(false);
  setModalStoreOpen(false);
  
  // Если это iOS устройство, открываем авторизационное окно
  if (isIOS) {
    setIsModalOpenAuth(true);
  } else {
    // Открываем другое модальное окно
    setIsCenterModalOpen(true);
  }
};

const handlePaymentSuccessStripe2 = () => {
  localStorage.setItem('DBB98', '9367');
  logAmplitudeEvent('e.2.0.5 - Success pay', { component: 'e.2.0.5 - Success pay' });
  
  // Закрываем все модальные окна
  setIsModalOpen(false); 
  setIsSecondModalOpen(false);
  setModalStoreOpen2(false);
  setModalStoreOpen(false);
  setIsThirdModalEmptyOpen(false);
  
  // Если это iOS устройство, открываем авторизационное окно
  if (isIOS) {
    setIsModalOpenAuth(true);
  } else {
    // Открываем другое модальное окно
    setIsCenterModalOpen2(true);
  }
};

const handlePaymentSuccessPay1 = () => {
  setPurchasedCoinsonboard(400);
  logAmplitudeEvent('e.2.0.5 - Success pay', { component: 'e.2.0.5 - Success pay' });
  localStorage.setItem('DBB98', '9367');
  
  // Закрываем все модальные окна
  setIsModalOpen(false); 
  setIsSecondModalOpen(false);
  setModalStoreOpen2(false);
  setModalStoreOpen(false);
  setIsThirdModalEmptyOpen(false);
  
  // Если это iOS устройство, открываем авторизационное окно
  if (isIOS) {
    setIsModalOpenAuth(true);
  } else {
    // Открываем другое модальное окно
    setIsCenterModalOpen2(true);
  }
};

const handlePaymentSuccessPay2 = () => {
  setPurchasedCoinsonboard(900);
  logAmplitudeEvent('e.2.0.5 - Success pay', { component: 'e.2.0.5 - Success pay' });
  localStorage.setItem('DBB98', '9367');
  
  // Закрываем все модальные окна
  setIsModalOpen(false); 
  setIsSecondModalOpen(false);
  setModalStoreOpen2(false);
  setModalStoreOpen(false);
  setIsThirdModalEmptyOpen(false);
  setIsPurpleModalOpen(false);

  // Если это iOS устройство, открываем авторизационное окно
  if (isIOS) {
    setIsModalOpenAuth(true);
  } else {
    // Открываем другое модальное окно
    setIsCenterModalOpen2(true);
  }
};

const handleClosePurple = () => {
  setIsPurpleModalOpen(false)
}

const handleCenterModalClose = () => {
  setIsCenterModalOpen(false); // Закрываем центрированное модальное окно
  setIsThirdModalEmptyOpen(true); // Открываем третье модальное окно
};

const handleCenterModalClose2 = () => {
  setIsCenterModalOpen2(false); // Закрываем центрированное модальное окно
};

const handlePayingCard1Click = () => {
  setPurchasedCoinsonboard(400); // Устанавливаем количество монет для первой карточки
  setModalStoreOpen2(true); // Открываем ModalStore
};

const handlePayingCard2Click = () => {
  setPurchasedCoinsonboard(900); // Устанавливаем количество монет для второй карточки
  setModalStoreOpen2(true); // Открываем ModalStore
};

const closeModalAuth = () => {
  localStorage.setItem('authModalShown', 'true');
  setIsModalOpenAuth(false);
  handleMatchClick();
};

const handleAppleSuccess = async (response: any) => {
  const appleIdToken = response.authorization.id_token;
  console.log('Apple ID Token:', appleIdToken);
  logAmplitudeEvent('e.1.2 - Auth on Apple', { component: 'e.1.2 - Auth on Apple' });

  try {
    // Привязываем токен Apple к пользователю
    const attachResponse = await fetch('https://golive.mobi/api/web/user/token/attach/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
      },
      body: JSON.stringify({
        id: userId,  // Используйте актуальный userId
        gp_id: null,
        apple_id: appleIdToken,
      }),
    });

    const attachData = await attachResponse.json();

    if (attachResponse.ok && attachData.id) {
      localStorage.setItem('authModalShown', 'true');
      setIsModalOpenAuth(false); // Закрываем модальное окно авторизации

    } else {
      console.error('Ошибка привязки токена:', attachData);
      logAmplitudeEvent('e.1.2.1 - Error backend attach token', { component: 'e.1.2.1 - Error backend attach token' });
    }
  } catch (error) {
    console.error('Error during Apple authentication:', error);
    logAmplitudeEvent('e.1.2.2 - Error apple auth', { component: 'e.1.2.2 - Error apple auth' });
  }
};

const emulateBPixelJS = () => {
  if (typeof window.BPixelJS !== 'undefined') {
    window.BPixelJS.conversion({
      url: 'https://deus-team.com/click',
    });
    console.log("BPixelJS конверсия вызвана вручную.");
  } else {
    console.error("BPixelJS не загружен для эмуляции.");
  }
};


// container emuclick



// container emuclick


  return (
    <>
    <div style={containerStyle}>

      <GradientBackground style={containerStyle} />
      <Container style={containerStyle}>

      <CoinsContainer>
    <CoinText>{balance !== null ? balance : 'Loading...'}</CoinText>
    <CoinImage src={CoinIcon} alt="Coins" />
  </CoinsContainer>

<div style={{ position: 'absolute', opacity: 0, width: '1px', height: '1px'}}>
  <Elements stripe={stripePromise}>
      <Stripe
        label={`100 Coins`}
        amount={100} // Преобразование суммы в центы
        payment_charge_id={4} // Передаем payment_charge_id
        onPaymentSuccess={handlePaymentSuccessPay2}
      />
    </Elements>
    </div>

    <button 
  style={{ width: '1px', height: '1px', display: 'none' }} 
  onClick={emulateBPixelJS}>
  Эмуляция BPixelJS
</button>

        <OnlineUsers>Currently 100<br /> people are online</OnlineUsers>
        <CentralText>Tap to start search</CentralText>
        <CircleImage src={circle_v2} alt="Circle background" />
        <HandTapStartImage src={hand_v2} alt="Tap to start" onClick={handleMatchClick} />
        <OptionButtonsContainer>
          <OptionButton onClick={handleGirlButtonClick}>
          <OptionIcon src={heartIcon} alt="Heart" />
  {selectedFilter === 'all' ? 'All users' : 'Premium'}
</OptionButton>
<OptionButton onClick={handleGlobalButtonClick}>
    <OptionIcon 
        src={
            selectedOption === 'balanced' || selectedOption === 'global'
                ? globeIcon
                : selectedCountry 
                    ? countries.find(country => country.code === selectedCountry)?.flag 
                    : globeIcon
        } 
        alt={
            selectedOption === 'balanced' 
                ? "Balanced"
                : selectedOption === 'global'
                    ? "Global"
                    : selectedCountry 
                        ? countries.find(country => country.code === selectedCountry)?.name 
                        : "Globe"
        }
        style={{ 
            width: selectedCountry ? '30px' : '24px', 
            height: selectedCountry ? '30px' : '24px', 
            borderRadius: selectedCountry ? '50%' : 'none' 
        }} 
    />
    {selectedOption === 'balanced' 
        ? 'Balanced' 
        : selectedOption === 'global' 
            ? 'Global'
            : selectedCountry 
                ? countries.find(country => country.code === selectedCountry)?.name 
                : 'Global'}
</OptionButton>
        </OptionButtonsContainer>
        <MatchButtonContainer>
          <MatchButton onClick={handleMatchClick}>
            Random Match
            <FreeBadge>{FreeBadgeContent()}</FreeBadge>
          </MatchButton>
        </MatchButtonContainer>
        <NavBar>
       {/*   <NavItem src={storeIcon} alt="Store" onClick={onStoreFreeCallClick} /> */}
          <NavItem src={storeIcon} alt="Store" onClick={onStoreClick} />
          <NavItem src={listIcon} alt="List" onClick={onListClick} />
          <NavItem src={videochatIcon} alt="Video Chat" onClick={() => { /* Добавьте логику для открытия видео-чата */ }} />
          <NavItem src={textchatIcon} alt="Text Chat" onClick={onChatsClick} />
        </NavBar>
      </Container>
  
      {isModalOpen && (
        <ModalOverlay style={containerStyle}>
          <ModalContent>
            <HeaderContainer>
              <CloseButton 
                src={popupclosebutton} 
                alt="Close" 
                onClick={handleClose} 
              />
              <WelcomeText>Welcome bonus</WelcomeText>
            </HeaderContainer>
            <CountdownContainer>
              <CountdownText>Countdown</CountdownText>

              <Timer>
                <Time>00</Time>
                <Separator>:</Separator>
                <Time>00</Time>
                <Separator>:</Separator>
               <Time isHighlighted>{formatTime(welcomeTimer)}</Time>
              </Timer>

            </CountdownContainer>

            <StartVipImage src={startvip} alt="Start VIP" />

            <div style={{ position: 'relative' }}>
  {/* Кнопка Continue */}
  <ContinueVipButton>Continue</ContinueVipButton>

  {/* Контейнер для Stripe оплаты, накладывается поверх кнопки */}
  <PaymentContainer>
  <div style={{ width: '300px', opacity: 0.01}} >
    <Elements stripe={stripePromise} >
      <Stripe 
        label={`100 Coins`}
        amount={100} // Преобразование суммы в центы
        payment_charge_id={1} // Передаем payment_charge_id
        onPaymentSuccess={handlePaymentSuccessStripe}
      />
    </Elements>
    </div>
  </PaymentContainer>
</div>

         {/*   <Stripe onPaymentSuccess={handlePaymentSuccess} /> */}

            <AgreementText>
  By purchasing you agree to our 
  <a href="https://deus.mobi/golive/terms" target="_blank" rel="noopener noreferrer"> Terms of Service</a> and 
  <a href="https://deus.mobi/golive/privacy" target="_blank" rel="noopener noreferrer"> Privacy Policy</a>.
</AgreementText>
          </ModalContent>
        </ModalOverlay>
      )}
  
      {isSecondModalOpen && (
        <CenteredModalOverlay style={containerStyle}>
          <ModalContentSecond>
            <HeaderContainer>
            <CloseButton 
  src={popupclosebutton} 
  alt="Close" 
  onClick={() => {
    setIsSecondModalOpen(false); // Закрываем модальное окно
    
    // Проверяем, если это iOS устройство, то запускаем handleMatchClick
    if (isIOS) {
      handleMatchClick(); // Запускаем match
    }
  }} 
/>
              <img 
                src={lastchance} 
                alt="Last Chance" 
                style={{ marginTop: '5px', width: '200px', height: 'auto', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
              />
            </HeaderContainer>
            <CountdownContainer>
              <CountdownText>Countdown</CountdownText>
              <Timer>
                <Time isHighlighted>{formatTimeWithHours(secondTimer)}</Time>
              </Timer>
            </CountdownContainer>
            <StartVipImage src={freetrialsecond} alt="Free trial" />
            
            <div style={{ position: 'relative' }}>
  {/* Кнопка Continue */}
  <ContinueVipButton>Continue</ContinueVipButton>

  {/* Контейнер для Stripe оплаты, накладывается поверх кнопки */}
  <PaymentContainer>
  <div style={{ width: '300px', opacity: 0.01}}>
    <Elements stripe={stripePromise}>
      <Stripe
        label={`100 Coins`}
        amount={100} // Преобразование суммы в центы
        payment_charge_id={1} // Передаем payment_charge_id
        onPaymentSuccess={handlePaymentSuccessStripe}
      />
    </Elements>
    </div>
  </PaymentContainer>
</div>

            <AgreementText>
  By purchasing you agree to our 
  <a href="https://deus.mobi/golive/terms" target="_blank" rel="noopener noreferrer"> Terms of Service</a> and 
  <a href="https://deus.mobi/golive/privacy" target="_blank" rel="noopener noreferrer"> Privacy Policy</a>.
</AgreementText>
          </ModalContentSecond>
        </CenteredModalOverlay>
      )}
  
  {isThirdModalOpen && (
  <ModalOverlay style={containerStyle}
  onClick={handleAllowAll}>
    <ModalContent style={{ position: 'relative', padding: '30px 0px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}
    onClick={handleAllowAll}
    >
      {/* Изображение "Allow Services" */}
      <img 
        src={allowservices2} 
        alt="Allow Services" 
        style={{
          width: '80%',
          height: 'auto',
          display: 'block',
          marginBottom: '20px' // Отступ снизу
        }}
        onClick={handleAllowAll}
      />
      
      {/* Кнопка "Allow All" */}
      <button
        onClick={handleAllowAll}
        style={{
          backgroundColor: '#FCD900',
          color: 'black',
          fontSize: '20px',
          fontWeight: 'bold',
          padding: '15px 30px',
          border: 'none',
          borderRadius: '50px',
          cursor: 'pointer',
          width: '35vh',
          marginTop: '20px' // Отступ сверху
        }}
      >
        Allow all
      </button>
    </ModalContent>
  </ModalOverlay>
)}

{isInstallApp && (
      <ModalOverlay style={containerStyle}>
        <ModalContent style={{ position: 'relative', padding: '30px 20px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}>
          {/* Изображение "Allow Services" */}
          <img 
            src={headerinstallpopup} 
            alt="Allow Services" 
            style={{
              width: '80%',
              height: 'auto',
              display: 'block',
              marginBottom: '20px' // Отступ снизу
            }}
          />
          
  

          {/* Кнопка "Allow All" */}
          <PWAInstall />
        </ModalContent>
      </ModalOverlay>
    )}

{isCenterModalOpen && (
  <CenterModal 
    onClose={handleCenterModalClose}
    purchasedCoins={purchasedCoinsonboard} // Передаем количество купленных монет
  />
)}

{isCenterModalOpen2 && (
  <CenterModal 
    onClose={handleCenterModalClose2}
    purchasedCoins={purchasedCoinsonboard} // Передаем количество купленных монет
  />
)}

{isThirdModalEmptyOpen && (
  <CenteredModalOverlay style={containerStyle}>
    <ModalContentSecond style={{ height: 'auto' }}>
      <HeaderContainer style={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'flex-start', 
        paddingTop: '20px', // Добавлен отступ сверху для пространства
        position: 'relative' // Положение для абсолютного позиционирования кнопки закрытия
      }}>
        <CloseButton 
          src={popupclosebutton} 
          alt="Close" 
          onClick={handlePaying}
          style={{
            position: 'absolute',
            top: '20px', // Выровнено с верхом контейнера
            left: '20px', // Отступ слева
            width: '36px', // Размер кнопки
            height: '36px', // Размер кнопки
            cursor: 'pointer',
            zIndex: 1000 // Убедитесь, что кнопка закрытия находится выше всех элементов
          }}
        />
        <img 
          src={manycoins} 
          alt="Many Coins" 
          style={{ 
            width: '150px', // Регулируемый размер изображения
            height: 'auto', // Сохранение пропорций
            marginBottom: '20px' // Отступ снизу
          }} 
        />
        <img 
          src={paycongratulations} 
          alt="Congratulations" 
          style={{ 
            width: '350px', // Регулируемый размер изображения
            height: 'auto', // Сохранение пропорций
            marginBottom: '20px' // Отступ снизу
          }} 
        />
        <div 
          style={{
            textAlign: 'center',
            color: 'white',
            fontSize: '24px',
            fontWeight: 'bold',
            marginBottom: '20px', // Отступ снизу для текста перед карточками
          }}
        >
          <p style={{ margin: 0 }}>
            You have bought <img src={CoinIcon} alt="Coin" style={{ width: '20px', verticalAlign: 'middle' }} /> 100
          </p>
          <p style={{ marginTop: '10px' }}>
            Get even more coins <br />with a <span style={{ color: '#FFD700' }}>special discount</span> 👇
          </p>
        </div>
        
        <div 
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center', // Центрирование карточек по горизонтали
            alignItems: 'center',
            gap: '20px', // Расстояние между карточками
            width: '100%', // Убедиться, что контейнер карточек занимает всю ширину
          }}
        >


<div style={{ position: 'relative' }}>
  {/* Кнопка Continue */}
  <img 
            src={payingcard1} 
            alt="Paying Card 1" 
            style={{ 
              width: '150px', // Регулируемый размер изображения
              height: 'auto', // Сохранение пропорций
            }} 
      
          />

  {/* Контейнер для Stripe оплаты, накладывается поверх кнопки */}
  <PaymentContainerV>
  <div style={{ width: '150px', opacity: 0.01}}>
    <Elements stripe={stripePromise}>
      <Stripe
        label={`100 Coins`}
        amount={100} // Преобразование суммы в центы
        payment_charge_id={2} // Передаем payment_charge_id
        onPaymentSuccess={handlePaymentSuccessPay1}
      />
    </Elements>
    </div>

  <div style={{ width: '150px', opacity: 0.01}}>
    <Elements stripe={stripePromise}>
      <Stripe
        label={`100 Coins`}
        amount={100} // Преобразование суммы в центы
        payment_charge_id={2} // Передаем payment_charge_id
        onPaymentSuccess={handlePaymentSuccessPay1}
      />
    </Elements>
    </div>

  <div style={{ width: '150px', opacity: 0.01}}>
    <Elements stripe={stripePromise}>
      <Stripe
        label={`100 Coins`}
        amount={100} // Преобразование суммы в центы
        payment_charge_id={2} // Передаем payment_charge_id
        onPaymentSuccess={handlePaymentSuccessPay1}
      />
    </Elements>
    </div>
  </PaymentContainerV>
</div>



<div style={{ position: 'relative' }}>
  {/* Кнопка Continue */}
  <img 
            src={payingcard2} 
            alt="Paying Card 2" 
            style={{ 
              width: '150px', // Регулируемый размер изображения
              height: 'auto', // Сохранение пропорций
            }} 
       
          />

  {/* Контейнер для Stripe оплаты, накладывается поверх кнопки */}
  <PaymentContainerV>
  <div style={{ width: '150px', opacity: 0.01}}>
    <Elements stripe={stripePromise}>
      <Stripe
        label={`100 Coins`}
        amount={100} // Преобразование суммы в центы
        payment_charge_id={4} // Передаем payment_charge_id
        onPaymentSuccess={handlePaymentSuccessPay2}
      />
    </Elements>
    </div>

    <div style={{ width: '150px', opacity: 0.01}}>
    <Elements stripe={stripePromise}>
      <Stripe
        label={`100 Coins`}
        amount={100} // Преобразование суммы в центы
        payment_charge_id={4} // Передаем payment_charge_id
        onPaymentSuccess={handlePaymentSuccessPay2}
      />
    </Elements>
    </div>

    <div style={{ width: '150px', opacity: 0.01}}>
    <Elements stripe={stripePromise}>
      <Stripe
        label={`100 Coins`}
        amount={100} // Преобразование суммы в центы
        payment_charge_id={4} // Передаем payment_charge_id
        onPaymentSuccess={handlePaymentSuccessPay2}
      />
    </Elements>
    </div>
  </PaymentContainerV>
</div>

        </div>
      </HeaderContainer>
    </ModalContentSecond>
  </CenteredModalOverlay>
)}
  
  <ModalSuperMatch 
  isOpen={isSuperMatchModalOpen}
  onClose={() => setIsSuperMatchModalOpen(false)}
  variant="primary"
>
  <div style={{ padding: '30px', paddingLeft: '30px', textAlign: 'left' }}>
    <h1 style={{ fontSize: '40px', fontWeight: 'bold', color: '#fff', margin: 0 }}>Match filter</h1>
    <h2 style={{ fontSize: '20px', fontWeight: 'bold', color: '#fff', marginTop: '20px', marginBottom: 0 }}>
      Match with
    </h2>
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px', gap: '20px' }}>
      <img
        src={selectedFilter === 'all' ? alluserson : allusersoff}
        alt="All users"
        style={{ width: '50%', cursor: 'pointer' }}
        onClick={handleAllUsersClick}
      />
      <img
        src={selectedFilter === 'premium' ? premiumuserson : premiumusersoff}
        alt="Premium users"
        style={{ width: '50%', cursor: 'pointer' }}
        onClick={handlePremiumUsersClick}
      />
    </div>

    {/* Добавляем текст Super Match */}
    <div style={{ marginTop: '30px' }}>
      <h1 style={{ fontSize: '24px', fontWeight: 'bold', color: '#fff', margin: 0 }}>Super Match</h1>
    </div>

    {/* Добавляем иконку S, текст описания Super Match и иконку монеты с количеством */}
    <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
      <img src={strange_s} alt="S" style={{ width: '40px', marginRight: '10px' }} />
      <p style={{ fontSize: '14px', color: '#fff', margin: 0, flexGrow: 1 }}>
        The Super Match filter helps you find more compatible matches
      </p>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img src={CoinIcon} alt="Coin" style={{ width: '24px', marginRight: '5px' }} />
        <span style={{ fontSize: '20px', color: '#fff' }}>20</span>
      </div>
      <img
        src={isSwitchOn ? filterswitchon : filterswitchoff}
        alt="Switch"
        style={{ width: '50px', marginLeft: '10px', cursor: 'pointer' }}
        onClick={handleSwitchClick}
      />
    </div>

    {/* Добавляем кнопку Go Match */}
    <div style={{ marginTop: '40px', textAlign: 'center' }}>
      <button
        onClick={() => {
          handleMatchClick();
          setIsSuperMatchModalOpen(false); // Закрываем модальное окно после нажатия
        }}
        style={{
          backgroundColor: '#FCD900',
          color: 'black',
          fontSize: '20px',
          fontWeight: 'bold',
          padding: '15px 0',
          border: 'none',
          borderRadius: '50px',
          width: '100%',
          cursor: 'pointer',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        }}
      >
        Go Match
      </button>
    </div>
  </div>
</ModalSuperMatch>

{isModalStoreOpen && (
  <ModalStore 
    onClose={() => setModalStoreOpen(false)}
    balance={balance || 0}
    amount={100}  // Устанавливаем значение amount в 100
    paymentChargeId={1} // Передаем нужный ID плана или идентификатор оплаты
    onPaymentSuccessAndClose={handlePaymentSuccessStripe}  // Закрываем модальное окно после успешной оплаты
  />
)}

{isPurpleModalOpen && (
  <PurpleModal />
)}

{isModalStoreOpen2 && (
  <ModalStore 
    onClose={() => setModalStoreOpen2(false)}
    balance={balance || 0}
    amount={purchasedCoinsonboard}  // Устанавливаем значение amount
    paymentChargeId={1}  // Передаем нужный ID плана или идентификатор оплаты
    onPaymentSuccessAndClose={handlePaymentSuccessStripe2}  // Закрываем модальное окно после успешной оплаты
  />
)}

  {/* Модальное окно */}
  {isModalOpenAuth && (
  <ModalOverlay>
    <ModalContent>
      <CloseButton src={popupclosebutton} alt="Close" onClick={closeModalAuth} />
      <ModalImage src={f_signpic} alt="Sign Pic" />


      {isLoading ? (
      <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100%', 
        paddingTop: '20px', 
        paddingBottom: '20px' 
      }}>
        <Oval color="#00BFFF" height={60} width={60} />
      </div>
    ) : (
      <ButtonContainer>
  

    {/*  <div style={{
              position: 'relative',
              width: '50%',
              padding: '15px',
              fontSize: '18px',
              fontWeight: 'bold',
              backgroundColor: '#FCD900',
              borderRadius: '50px',
              cursor: 'pointer',
              margin: '20px auto',
            }}
              
            >
              Continue
              <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
                <div style={{ opacity: 0.01 }}>
                <GoogleLogin
                  onSuccess={handleGoogleSuccess}
                  ux_mode="popup"
                  useOneTap={true}
                  auto_select={true}
                  cancel_on_tap_outside={false}
                />
                </div>
              </div>
              </div> */}

              {/* Если устройство не iOS, показываем GoogleLogin */}
              {!isIOS ? (
                  <div style={{
                    position: 'relative',
                    width: '50%',
                    padding: '15px',
                    fontSize: '18px',
                    fontWeight: 'bold',
                    backgroundColor: '#FCD900',
                    borderRadius: '50px',
                    cursor: 'pointer',
                    margin: '20px auto',
                  }}>
                    Continue
                    <div style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>

                    </div>
                  </div>
                ) : (

                  <div style={{
                    position: 'relative',
                    width: '50%',
                    padding: '15px',
                    backgroundColor: '#FCD900',
                    borderRadius: '50px',
                    cursor: 'pointer',
                    margin: '20px auto',
                  }}>
                    <span style={{
                      position: 'relative',  // Для сохранения текстового слоя на переднем плане
                      zIndex: 1,  // Текст всегда на переднем плане
                      fontSize: '18px',
                      fontWeight: 'bold',
                      textAlign: 'center',  // Центрирование текста
                      display: 'block',  // Для того чтобы span занял всю ширину
                    }}>
                      Continue
                    </span>
                    <div style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                      <div style={{ opacity: 0.01 }}>

                      </div>
                    </div>
                  </div>


                 
                )}
           
           
  
{/* <APB onSuccess={handleAppleSuccess} />
    <AuthButton onClick={handleSubmit}>Continue with Google</AuthButton>
     <AuthButton onClick={handleSubmit}>Continue with Apple</AuthButton> */}

      </ButtonContainer>

)}
    {hasError && <p>Error. Please try again.</p>}

      {/* Текст внизу */}
      <DisclaimerText>
  By logging in, you confirm you’re over 18 years old and agree to our 
  <a href="https://deus.mobi/golive/terms" target="_blank" rel="noopener noreferrer"> Terms of Use </a> 
  and 
  <a href="https://deus.mobi/golive/privacy" target="_blank" rel="noopener noreferrer"> Privacy Policy</a>.
</DisclaimerText>

    </ModalContent>
  </ModalOverlay>
)}

{isCountryModalOpen && (
 <ModalCountry 
 isOpen={isCountryModalOpen}
 onClose={() => setIsCountryModalOpen(false)}
>
 <div style={{ padding: '30px', color: '#fff', display: 'flex', flexDirection: 'column', height: '100%', background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.7) 100%)' }}>
     <h1 style={{ fontSize: '30px', fontWeight: 'bold', color: '#fff', margin: 0, marginTop: '10px' }}>Country filter</h1>
     <div style={{ flexGrow: 1, marginTop: '20px', maxHeight: '400px', overflowY: 'auto', position: 'relative', borderBottomRightRadius: '25px', borderBottomLeftRadius: '25px' }}>
         {/* Добавляем кнопки balanced и global */}
         <div
             style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginBottom: '20px' }}
             onClick={() => handleBalancedCheckboxClick()}
         >
             <img
                 src={selectedOption === 'balanced' ? checkon : checkoff}
                 alt="Balanced"
                 style={{ marginRight: '10px', width: '18px', height: '18px' }}
             />
             <span style={{ fontSize: '20px', color: '#fff' }}>Balanced</span>
         </div>
         <div
             style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginBottom: '20px' }}
             onClick={() => handleGlobalCheckboxClick()}
         >
             <img
                 src={selectedOption === 'global' ? checkon : checkoff}
                 alt="Global"
                 style={{ marginRight: '10px', width: '18px', height: '18px' }}
             />
             <span style={{ fontSize: '20px', color: '#fff' }}>Global</span>
         </div>

         {countries.map((country: Country) => (
             <div
                 key={country.code}
                 style={{ 
                     display: 'flex', 
                     alignItems: 'center', 
                     cursor: 'pointer', 
                     marginBottom: '20px', 
                     justifyContent: 'space-between' // для выравнивания монет справа
                 }}
                 onClick={() => handleCountryClick(country.code)}
             >
                 <div style={{ display: 'flex', alignItems: 'center' }}>
                     <img
                         src={selectedCountry === country.code ? checkon : checkoff}
                         alt={country.name}
                         style={{ marginRight: '10px', width: '18px', height: '18px' }}
                     />
                     <img
                         src={country.flag}
                         alt={`${country.name} flag`}
                         style={{ 
                             width: '30px', // увеличиваем размер флагов
                             height: '30px', 
                             borderRadius: '50%', // делаем флаги круглыми
                             marginRight: '10px',
                             objectFit: 'cover', // чтобы изображение флага не деформировалось
                             display: 'inline-block', // для выравнивания по центру
                             verticalAlign: 'middle' // для центровки по вертикали
                         }}
                     />
                     <span style={{ fontSize: '20px', color: '#fff', verticalAlign: 'middle' }}>{country.name}</span>
                 </div>
                 <div style={{ display: 'flex', alignItems: 'center' }}>
                     <img
                         src={CoinIcon} // путь к иконке монеты
                         alt="Coin"
                         style={{ width: '20px', height: '20px', marginRight: '5px' }}
                     />
                     <span style={{ fontSize: '20px', color: '#fff', lineHeight: '20px', fontWeight: 'bold' }}>20</span>
                 </div>
             </div>
         ))}
         {/* Добавляем замыливание */}
         
         <button
             onClick={() => {
                 handleMatchClick();
                 setIsCountryModalOpen(false);
             }}
             style={{
                 position: 'sticky',
                 bottom: 0,
                 backgroundColor: '#FCD900',
                 color: 'black',
                 fontSize: '20px',
                 fontWeight: 'bold',
                 padding: '15px 0',
                 border: 'none',
                 borderRadius: '50px',
                 width: '100%',
                 cursor: 'pointer',
                 boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                 zIndex: 10, // Чтобы кнопка была поверх списка
             }}
         >
             Go Match
         </button>
         
     </div>
 </div>
</ModalCountry>
)}</div>
    </>
  );
};

export default RandomCall;