import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import splashLogo from '../res/logo_splash.webp';
import { initializeAmplitude, logAmplitudeEvent, setAmplitudeUserProperties } from '../amplitude';
import Cookies from 'js-cookie';
import f_bar2 from '../res_land/f_bar2.webp';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  flex-direction: column;
`;

const Logo = styled.img`
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
`;

const Info = styled.p`
  margin-top: 20px;
  font-size: 16px;
`;

const StartApp: React.FC<{ onTimeout: () => void }> = ({ onTimeout }) => {
  const [fbp, setFbp] = useState<string | null>(null);
  const [fbc, setFbc] = useState<string | null>(null);
  const [currentUrl, setCurrentUrl] = useState<string>('');
  const [showInfo, setShowInfo] = useState(true);



  const eventId = Math.random().toString(36).substring(2) + Date.now().toString(36);
  localStorage.setItem('eventf', eventId);

  const purchaseId = Math.random().toString(36).substring(2) + Date.now().toString(36);
  localStorage.setItem('purchasef', purchaseId);

  useEffect(() => {
    // Инициализация Amplitude и вывод device_id
    initializeAmplitude();

    const device_id = localStorage.getItem('device_id'); // Чтение device_id из localStorage после инициализации
    if (device_id) {
      console.log("Device ID:", device_id);
    }

    logAmplitudeEvent('e.0.0 - Initialization/Login to the site', {
      component: 'e.0.0 - Initialization/Login to the site',
    });

    // Устанавливаем свойства пользователя для Amplitude
    setAmplitudeUserProperties({
      os_version: navigator.userAgent.match(/Android\s([0-9\.]+)/) ? RegExp.$1 : 'Unknown',
      device_model: navigator.userAgent.match(/\(([^)]+)\)/) ? RegExp.$1 : 'Unknown',
      locale: navigator.language || 'Unknown',
      screen_height: window.screen.height || 'Unknown',
      screen_density: window.devicePixelRatio || 'Unknown',
      cpu_cores: navigator.hardwareConcurrency || 'Unknown',
      user_agent: navigator.userAgent || 'Unknown',
      timezone_abbreviation: Intl.DateTimeFormat().resolvedOptions().timeZone || 'Unknown',
    });

    const fbpCookie = Cookies.get('_fbp') || null;
    const fbcCookie = Cookies.get('_fbc') || null;
    setFbp(fbpCookie);
    setFbc(fbcCookie);

    setCurrentUrl(window.location.href);

    const urlParams = new URLSearchParams(window.location.search);
    const redParam = urlParams.get('red');
    if (redParam === 'no') {
      setShowInfo(false);
    }

    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    const isAndroid = /Android/i.test(navigator.userAgent);
    const isFacebookInAppBrowser = /Instagram|FB_IAB|FBAN/.test(navigator.userAgent);
    
    if (!(isIOS && /Instagram|FB_IAB|FBAN/.test(navigator.userAgent)) && (isAndroid || !isIOS)) {
      onTimeout();
    }

    // Таймер для вызова onTimeout, без перезагрузки страницы
    const timer = setTimeout(() => {
      onTimeout();
    }, 1000);

    return () => clearTimeout(timer);
  }, [onTimeout]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const fbpParam = urlParams.get('fbp');
    const fbcParam = urlParams.get('fbc');

    if (fbpParam) {
      Cookies.set('_fbp', fbpParam, { expires: 365 });
      setFbp(fbpParam);
    }

    if (fbcParam) {
      Cookies.set('_fbc', fbcParam, { expires: 365 });
      setFbc(fbcParam);
    }
  }, []);

  

  return (
    <Container>
     
      {!showInfo && (
        <>
          <Info>FBP: {fbp ? fbp : 'Not found'}</Info>
          <Info>FBC: {fbc ? fbc : 'Not found'}</Info>
          <Info>Текущий URL: {currentUrl}</Info>
        </>
      )}
    </Container>
  );
};

export default StartApp;