import React, { useEffect, useState } from 'react';
import StartApp from './stage/start_app';
import Registration from './stage/registration';
import RandomCall from './stage/RandomCall';
import WaitConnect from './stage/WaitConnect';
import CallScreen from './stage/CallScreen';
import Store from './stage/Store';
import StoreFreeCall from './stage/StoreFreeCall';
import Chats from './stage/Chats';
import Feed from './stage/Feed'; // Импортируем компонент Feed
import InChat from './stage/InChat'; // Импортируем компонент InChat
import Cookies from 'js-cookie';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { initializeAmplitude } from './amplitude'; // Импортируем нашу инициализацию Amplitude



const stripePromise = loadStripe('pk_live_51PvIEsCUXAP9itNhSzAOu01dTlCRi7euhnyb8Faj7muKHI6dc72hQbOvTKQmBseO8RZRpHbeBMvOxEJJPvuBBVif00L0f4MtYm'); 

// Функция для проверки платформы
const isMobilePlatform = () => {
  const userAgent = navigator.userAgent || navigator.vendor;

  // Проверяем Android
  if (/android/i.test(userAgent)) {
    return true;
  }

  // Проверяем iOS
  if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
    return true;
  }

  return false;
};

function App() {

  useEffect(() => {
    initializeAmplitude(); // Инициализируем Amplitude при загрузке приложения
  }, []);

  const [userId, setUserId] = useState<string | null>(null);
  const [showRegistration, setShowRegistration] = useState<boolean>(false);
  const [activeComponent, setActiveComponent] = useState<
    'RandomCall' | 'WaitConnect' | 'CallScreen' | 'Store' | 'StoreFreeCall' | 'Chats' | 'Feed' | 'InChat'
  >('RandomCall');
  const [dialogId, setDialogId] = useState<string | null>(null);
  const [userData, setUserData] = useState<{ name: string; avatar: string } | null>(null);
  const [selectedChat, setSelectedChat] = useState<any | null>(null); // Состояние для выбранного чата
  const [showModalAfterRegistration, setShowModalAfterRegistration] = useState<boolean>(false); // Новый стейт

  // Состояние для хранения информации о мобильной платформе
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    // Определяем платформу при загрузке компонента
    setIsMobile(isMobilePlatform());

    const userIdFromCookie = Cookies.get('userId');
    setUserId(userIdFromCookie || null);
  }, []);

  const handleStartAppTimeout = () => {
    setShowRegistration(true);
  };

  const handleRegistrationSuccess = (newUserId: string) => {
    setUserId(newUserId);
    Cookies.set('userId', newUserId, { expires: 365 });
    setShowModalAfterRegistration(true);
  };

  const loadWaitConnect = () => setActiveComponent('WaitConnect');
  const loadRandomCall = () => setActiveComponent('RandomCall');
  const loadCallScreen = (roomId: string, userData: { name: string; avatar: string }) => {
    setActiveComponent('CallScreen');
    setDialogId(roomId);
    setUserData(userData);
  };
  const loadStore = () => setActiveComponent('Store');
  const loadStoreFreeCall = () => setActiveComponent('StoreFreeCall');
  const loadChats = () => setActiveComponent('Chats');
  const loadFeed = () => setActiveComponent('Feed');

  const loadInChatFromFeed = (chat: any) => {
    setSelectedChat(chat);
    setActiveComponent('InChat');
  };

  const returnToPreviousComponent = () => {
    if (selectedChat) {
      setSelectedChat(null);
      setActiveComponent('Feed');
    } else {
      setActiveComponent('Chats');
    }
  };

  // Применяем стили только если не мобильная платформа
  const containerStyle = !isMobile ? { width: '25%', margin: '0 auto' } : {};

  if (userId) {
    if (activeComponent === 'RandomCall') {
      return (
        <div style={containerStyle}>
          <Elements stripe={stripePromise}>
          <RandomCall onMatchClick={loadWaitConnect} onListClick={loadFeed} onStoreFreeCallClick={loadStoreFreeCall} onStoreClick={loadStore} onChatsClick={loadChats} showModal={showModalAfterRegistration} />
          </Elements>
        </div>
      );
    } else if (activeComponent === 'WaitConnect') {
      return (
        <div style={containerStyle}>
          <WaitConnect onCallScreenLoad={loadCallScreen} onClose={loadRandomCall} />
        </div>
      );
    } else if (activeComponent === 'CallScreen' && userData) {
      return (
        <div style={containerStyle}>
          <CallScreen
            roomId={dialogId!}
            onClose={loadWaitConnect}
            onTimerEndOrContinue={loadRandomCall} // Передаем пропс здесь
            userData={userData}
            onStoreEndCall={loadStoreFreeCall}
          />
        </div>
      );
    } else if (activeComponent === 'Store') {
      return (
        <div style={containerStyle}>
          <Store
            onVideoChatClick={loadRandomCall}
            onStoreClick={loadStore}
            onListClick={loadFeed} // Передаем функцию для переключения на Feed
            onTextChatClick={loadChats}
          />
        </div>
      );
    } else if (activeComponent === 'Chats') {
      return (
        <div style={containerStyle}>
          <Chats onStoreClick={loadStore} onListClick={loadFeed} onVideoChatClick={loadRandomCall} onTextChatClick={loadChats} />
        </div>
      );
    } else if (activeComponent === 'Feed') {
      return (
        <div style={containerStyle}>
          <Feed
            onStoreClick={loadStore}
            onListClick={loadFeed}
            onVideoChatClick={loadRandomCall}
            onTextChatClick={loadChats}
            onChatClick={loadInChatFromFeed} // Новая функция для открытия InChat из Feed
          />
        </div>
      ); 
    }
    else if (activeComponent === 'StoreFreeCall') {
      return (
        <div style={containerStyle}>
          <StoreFreeCall
          onMatchClick={loadWaitConnect} 
            onStoreClick={loadStore}
            onListClick={loadFeed}
            onVideoChatClick={loadRandomCall}
            onTextChatClick={loadChats}
          />
        </div>
      );
      
    }
    else if (activeComponent === 'InChat' && selectedChat) {
      return (
        <div style={containerStyle}>
          <InChat chat={selectedChat} onBack={returnToPreviousComponent} />
        </div>
      );
    }
  }

  if (showRegistration) {
    return (
      <div style={containerStyle}>
        <GoogleOAuthProvider clientId="416390904497-18hq938uv0tr5skvad268n9u0fouic4n.apps.googleusercontent.com">
          <Registration onRegistrationSuccess={handleRegistrationSuccess} />
        </GoogleOAuthProvider>
      </div>
    );
  }

  return (
    <div style={containerStyle}>
      <StartApp onTimeout={handleStartAppTimeout} />
    </div>
  );
}

export default App;