// src/FingerprintTracker.jsx
import { useEffect } from 'react';
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';

export default function FingerprintTracker() {
  const { isLoading, error, data, getData } = useVisitorData(
    { extendedResult: true },
    { immediate: true }
  );

  useEffect(() => {
    if (!isLoading && data) {
      console.log('Visitor Data:', data);
    } else if (error) {
      console.error('Error fetching visitor data:', error.message);
    }
  }, [isLoading, data, error]);

  return null; // Ничего не рендерим
}