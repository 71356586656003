import React, { ReactNode } from 'react';
import styled from 'styled-components';
import CoinIcon from '../res/coin.webp'; // Укажите правильный путь к иконке монет
import GPB from '../res/googlepaybutton.webp'; // Укажите правильный путь к изображению кнопки Google Pay
import { Elements } from '@stripe/react-stripe-js'; // Импортируем Elements из Stripe
import { loadStripe } from '@stripe/stripe-js'; // Импортируем loadStripe
import Stripe from '../components/Stripe'; // Импортируем твой компонент PaymentModal

// Создаем Stripe объект с твоим публичным ключом
const stripePromise = loadStripe('pk_live_51PvIEsCUXAP9itNhSzAOu01dTlCRi7euhnyb8Faj7muKHI6dc72hQbOvTKQmBseO8RZRpHbeBMvOxEJJPvuBBVif00L0f4MtYm');

interface ModalProps {
  onClose: () => void;
  balance: number;
  amount: number;
  children?: ReactNode; // Сделаем свойство children опциональным
  onPaymentSuccessAndClose: () => void;
  paymentChargeId: number; // Добавляем ID платежного плана
}

// Затемненный фон для модального окна
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0; 
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1005; 
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

// Стили для модального контента
const ModalContent = styled.div`
  background-color: #fff;
  width: 100%;
  height: 75%;
  border-radius: 20px 20px 0 0; 
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column; /* Flexbox для вертикальной компоновки */
  justify-content: space-between; /* Распределение элементов между верхом и низом */
`;

// Верхняя панель
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 10px;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
`;

const Title = styled.h3`
  font-size: 18px;
  font-weight: bold;
  margin: 0;
`;

const BalanceContainer = styled.div`
  display: flex;
  align-items: center;
`;

const BalanceText = styled.span`
  font-size: 16px;
  font-weight: bold;
  margin-right: 5px;
`;

const CoinImage = styled.img`
  width: 24px;
  height: 24px;
`;

// Секция для отображения количества монет
const AmountSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;

const AmountText = styled.h4`
  font-size: 20px;
  font-weight: bold;
`;

const CoinAmount = styled.div`
  display: flex;
  align-items: center;
`;

const AmountNumber = styled.span`
  font-size: 28px;
  font-weight: bold;
  margin-left: 10px;
`;

// Стили для кнопки Google Pay
const GooglePayButton = styled.img`
  display: block;
  width: 100%; // Кнопка займет всю ширину родительского контейнера
`;

// Контейнер для кнопок и контента Stripe, чтобы они были прижаты к низу
const PaymentContainer = styled.div`
  margin-top: auto;
  height: 25%
`;

// Проверка на мобильную платформу
const isMobilePlatform = () => {
    const userAgent = navigator.userAgent || navigator.vendor;
    if (/android/i.test(userAgent)) return true;
    if (/iPad|iPhone|iPod/.test(userAgent)) return true;
    return false;
};

const isMobile = isMobilePlatform();
const containerStyle = !isMobile ? { width: '25%', left: 'auto', right: 'auto' } : {};

// Функция для обработки успешной оплаты
const ModalStore: React.FC<ModalProps> = ({ onClose, balance, amount, onPaymentSuccessAndClose, paymentChargeId }) => {

  // Обработка успешной оплаты
  const handlePaymentSuccess = () => {
    console.log('Payment successful');
    onClose(); // Закрыть текущее модальное окно
    onPaymentSuccessAndClose(); // Вызвать функцию для открытия CenterModal
  };

  return (
    <ModalOverlay style={containerStyle}>
      <ModalContent>
        {/* Верхняя панель с заголовком и балансом */}
        <Header>
          <CloseButton onClick={onClose}>×</CloseButton>
          <Title>Get coins</Title>
          <BalanceContainer>
            <BalanceText>{balance}</BalanceText>
            <CoinImage src={CoinIcon} alt="Coin" />
          </BalanceContainer>
        </Header>

        {/* Секция с количеством выбранных монет */}
        <AmountSection>
          <AmountText>Coins Amount</AmountText>
          <CoinAmount>
            <CoinImage src={CoinIcon} alt="Coin" />
            <AmountNumber>{amount}</AmountNumber>
          </CoinAmount>
        </AmountSection>

        {/* Кнопка Google Pay */}
        <GooglePayButton src={GPB} alt="Google Pay" />

        {/* Контейнер для размещения кнопок Stripe и Google Pay */}
        <PaymentContainer>
          <Elements stripe={stripePromise}>
            <Stripe
              label={`100 Coins`}
              amount={100} // Преобразование суммы в центы
              payment_charge_id={1} // Передаем payment_charge_id
              onPaymentSuccess={handlePaymentSuccess}
            />
          </Elements>
        </PaymentContainer>
      </ModalContent>
    </ModalOverlay>
  );
};

export default ModalStore;